import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

import { updateDiscount } from 'redux/actions/cart/actions';

import { applyOrderDiscount } from 'api/discounts';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';

import CheckCircle from '@material-ui/icons/CheckCircle';
import ArrowForward from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minHeight: 'fit-content',
    width: '100%',
  },
}));

const Promo = ({ dispatch, orderDetails, className, user, ...rest }) => {
  const classes = useStyles();

  const [refreshing, setRefreshing] = useState(false);
  const [hasDiscount, setHasDiscount] = useState(!!(orderDetails  && orderDetails.discountCode));
  const [errorMessage, setErrorMessage] = useState(null);
  const [discountCode, setDiscountCode] = useState((orderDetails  && orderDetails.discountCode) || '');

  //console.log(`[Promo] hasDiscount: ${hasDiscount} | orderDetails.discountCode: ${orderDetails.discountCode} `, orderDetails);

  useEffect(() => {
    setHasDiscount(!!(orderDetails  && orderDetails.discountCode));
    setErrorMessage(null);
    setDiscountCode((orderDetails  && orderDetails.discountCode) || '');
  }, [orderDetails])

  const handleDiscountCodeChange = (e) => {
    const value = e.target.value.toUpperCase();

    //console.log(value);
    setDiscountCode(value);
    setErrorMessage(null);
  }

  const applyDiscountCode = () => {
    /*
    if (!discountCode){
      setErrorMessage(`MISSING PROMO CODE`);
      return;
    }
    */

    if (!orderDetails || !orderDetails.subTotalAmount){
      setErrorMessage(`EMPTY CART`);
      return;
    }

    setRefreshing(true);
    setHasDiscount(false);

    //console.log(`[applyDiscountCode] discountCode: ${discountCode}`);

    applyOrderDiscount(orderDetails, discountCode)
    .then((discountOffer) => {

      //console.log(`[applyDiscountCode] discountOffer: `, discountOffer);

      dispatch(updateDiscount(discountOffer));

      setHasDiscount(true);
      setRefreshing(false);
      setErrorMessage(null);
    })
    .catch((errorText) => {
      //console.log(errorText);

      try{
        const error = JSON.parse(errorText);
        setErrorMessage(error.message || 'INVALID PROMO CODE');
      }
      catch(e){
        console.log(`Error parsing ${errorText} | `, e);
        setErrorMessage(errorText || 'INVALID PROMO CODE');
      }

      setRefreshing(false);
      setHasDiscount(false);
      //setDiscountCode('');
      dispatch(updateDiscount(null));
    });
  }

  const waitingIcon = <CircularProgress color="secondary" size={20} />;

  const confirmButton = <IconButton size="small"
                            onClick={applyDiscountCode}>
                            <ArrowForward />
                          </IconButton>;

  const validButton = <IconButton size="small" color="primary"
                            onClick={applyDiscountCode}>
                            <CheckCircle />
                          </IconButton>;

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Box p={1} ml={1} mr={1} bgcolor="background.paper" >
        <TextField
          error={!!errorMessage}
          helperText={errorMessage}
          label={!!errorMessage ? "ERROR" : "PROMO CODE"}
          placeholder="Enter promo code"
          id="discount-code"
          variant="outlined"
          size="small"
          disabled={refreshing}
          value={discountCode}
          //defaultValue=""
          onChange={handleDiscountCodeChange}
          InputLabelProps={{
                      shrink: true,
                    }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                { refreshing ? waitingIcon : (hasDiscount ? validButton : confirmButton) }
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </div>
  );
};

Promo.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = ({ cart }) => ({
//  discountOffer: cart.discountOffer,
});

export default connect(
  mapStateToProps,
)(Promo);
