import React from 'react';
import PropTypes from 'prop-types';

import Sort from '../Sort';

const ShelfHeader = props => {
  return (
    <div className="shelf-container-header">
      <small className="products-found">
        <span>
        {
          props.productsLength > 0 ? `${props.productsLength} Item(s) found.` :
          `Coming soon. Contact us at info@eatsimplified.com  or (408) 800-0886.`
        }
        </span>
      </small>
      <Sort />
    </div>
  );
};

ShelfHeader.propTypes = {
  productsLength: PropTypes.number.isRequired
};

export default ShelfHeader;
