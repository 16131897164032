import { version } from '../package.json';

export const clientVersion = version;

export const serverBaseUrl = 'https://us-central1-eatsimplified.cloudfunctions.net/api/v1';
//export const serverBaseUrl = 'https://eatsimplified.com/api/v1';
//export const serverBaseUrl = 'http://localhost:5001/eatsimplified/us-central1/api/v1';

export const googleMapsApiKey = '';

export const stripeConfig = {
  live: true,
  testPublishableKey: "pk_test_6wi3H3uBGG1YnZkUwE97Jq1z00PH0AlF43",
  livePublishableKey: "pk_live_of4IFQyAoVRvUqQuBMxILqMg00MBqFYNaI"
}

export const enableDiscounts = true;
