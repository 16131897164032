import { FETCH_PRODUCTS } from './actionTypes';
import axios from 'axios';

//const menuJson = require('https://eatsimplified.com/assets/data/menu.json');
const menuJson = require('../../../assets/shopping/menu.json');

const compare = {
  default: (a, b) => {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
  },
  nameAscending: (a, b) => {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
    return 0;
  },
  nameDescending: (a, b) => {
    if (a.title > b.title) return -1;
    if (a.title < b.title) return 1;
    return 0;
  },
  lowestprice: (a, b) => {
    if (a.price < b.price) return -1;
    if (a.price > b.price) return 1;
    return 0;
  },
  highestprice: (a, b) => {
    if (a.price > b.price) return -1;
    if (a.price < b.price) return 1;
    return 0;
  }
};

export const fetchProducts = (filters, sortBy, callback) => dispatch => {
  return Promise.resolve()
  .then(() => {
    let products = menuJson.products.filter(p => {
      if (p.disabled) return false;

      if (p.type !== filters.type) return false;

      if (!filters.categories || filters.categories.length == 0) return true;

      return filters.categories
                    .find(f => p.categories.find(category => category === f))
    });

    if (!!sortBy) {
      products = products.sort(compare[sortBy]);
    }

    if (!!callback) {
      callback();
    }

    return dispatch({
      type: FETCH_PRODUCTS,
      payload: products
    });
  })
  .catch(err => {
    console.log('Could not fetch products. Try again later.');
  });
};
