/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, colors } from '@material-ui/core';

import CartItem from './CartItem';

import { updateCart } from '../../../../redux/actions/total/actions';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  cartItem: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 10
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  card: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }

}));

const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
//  });
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const Cart = ({ dispatch,
                openCart,
                cartProducts, cartTotal,
                newProduct, productToRemove, productToChange,
                //, updateCart,
                className, ...rest }) => {

  const classes = useStyles();

  const previous = usePrevious({newProduct, productToRemove, productToChange});

  useEffect(() => {
    if (previous){
      //console.log(`[Cart] previous.newProduct: `, previous.newProduct);
      //console.log(`[Cart] newProduct: `, newProduct);
      if (previous.newProduct !== newProduct) {
        _addProduct(newProduct);
      }

      if (previous.productToRemove !== productToRemove) {
        _removeProduct(productToRemove);
      }

      if (previous.productToChange !== productToChange) {
        _changeProductQuantity(productToChange);
      }
    }
//  });
  }, [newProduct, productToRemove, productToChange, cartProducts]);


  const _addProduct = product => {
    //console.log(`[Cart] _addProduct`);
    let productAlreadyInCart = false;

    cartProducts.forEach(cp => {
      if (cp.id === product.id) {
        cp.quantity += product.quantity;
        productAlreadyInCart = true;
      }
    });

    if (!productAlreadyInCart) {
      cartProducts.push(product);
    }

    dispatch(updateCart(cartProducts));
    openCart && openCart();
  };

  const _removeProduct = product => {
    const index = cartProducts.findIndex(p => p.id === product.id);
    if (index >= 0) {
      cartProducts.splice(index, 1);
      dispatch(updateCart(cartProducts));
    }
  };

  const _changeProductQuantity = changedProduct => {
    const product = cartProducts.find(p => p.id === changedProduct.id);
    product.quantity = changedProduct.quantity;
    if (product.quantity <= 0) {
      _removeProduct(product);
    }
    dispatch(updateCart(cartProducts));
  }

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {cartProducts.map((product, i) => (
          <CartItem
            className={classes.cartItem}
            disableGutters
            key={product.id}
            product={product}
            removeProduct={_removeProduct}
            changeProductQuantity={_changeProductQuantity}
            divider={i < cartProducts.length - 1}/>
      ))}
    </List>
  );
};

Cart.propTypes = {
  className: PropTypes.string,
  cartProducts: PropTypes.array.isRequired,
  newProduct: PropTypes.object,
  productToRemove: PropTypes.object,
  productToChange: PropTypes.object,
  cartTotal: PropTypes.object,
};

const mapStateToProps = ({ cart, total }) => ({
  cartProducts: cart.products,
  newProduct: cart.productToAdd,
  productToRemove: cart.productToRemove,
  productToChange: cart.productToChange,
  cartTotal: total.data
});

export default connect(
//  mapStateToProps,
  //{ loadCart, updateCart, removeProduct, changeProductQuantity }
)(Cart);
