import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, ListSubheader } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import HistoryIcon from '@material-ui/icons/History';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RedeemIcon from '@material-ui/icons/Redeem';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';

import { Profile, SidebarNav, /*UpgradePlan*/ } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = ({ open, variant, onClose, className, user, ...rest }) => {
  const classes = useStyles();

  const pages = [
    {
      title: 'Order',
      href: '/order',
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'History',
      href: '/history',
      icon: <HistoryIcon />
    },
    {
      title: 'Account',
      href: '/account',
      superAdmin: true,
      icon: <AccountBoxIcon />
    },
    {
      title: 'Settings',
      href: '/settings',
      superAdmin: true,
      icon: <SettingsIcon />
    },
  ];

  const staffPages = [
    {
      title: 'Manage Deliveries',
      href: '/manage-deliveries',
      staff: true,
      icon: <LocalShippingIcon />
    },
    {
      title: 'Manage Orders',
      href: '/manage-orders',
      superAdmin: true,
      icon: <RestaurantIcon />
    },
  ];

  const adminPages = [
    {
      title: 'Manage Menu',
      href: '/manage-menu',
      admin: true,
      icon: <RestaurantMenuIcon />
    },
    {
      title: 'Manage Discounts',
      href: '/manage-discounts',
      admin: true,
      icon: <RedeemIcon />
    },
    {
      title: 'User Roles',
      href: '/user-roles',
      admin: true,
      icon: <PeopleIcon />
    },
    {
      title: 'User Discounts',
      href: '/user-discounts',
      admin: true,
      icon: <PeopleIcon />
    },
  ];

  const superAdminPages = [
    {
      title: 'Admin',
      href: '/admin',
      superAdmin: true,
      icon: <DashboardIcon />
    },
    {
      title: 'Consulting',
      href: '/products',
      superAdmin: true,
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'Insights',
      href: '/dashboard',
      superAdmin: true,
      icon: <DashboardIcon />
    },
    {
      title: 'Reach',
      href: '/users',
      superAdmin: true,
      icon: <PeopleIcon />
    },
    {
      title: 'Acquistion',
      href: '/login',
      superAdmin: true,
      icon: <LockOpenIcon />
    },
    {
      title: 'Viral',
      href: '/typography',
      superAdmin: true,
      icon: <TextFieldsIcon />
    },
    {
      title: 'The Situation Room',
      href: '/icons',
      superAdmin: true,
      icon: <ImageIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile user={user}/>
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
          user={user}
        />
        { user.staff && (
          <div>
            <Divider className={classes.divider} />
            <SidebarNav
              className={classes.nav}
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  STAFF
                </ListSubheader>
              }
              pages={staffPages}
              user={user}
            />
          </div>
        )}
        { user.admin && (
          <div>
            <Divider className={classes.divider} />
            <SidebarNav
              className={classes.nav}
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  ADMIN
                </ListSubheader>
              }
              pages={adminPages}
              user={user}
            />
          </div>
        )}
        { user.superAdmin && (
          <div>
            <Divider className={classes.divider} />
            <SidebarNav
              className={classes.nav}
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  SUPER ADMIN
                </ListSubheader>
              }
              pages={superAdminPages}
              user={user}
            />
          </div>
        )}
        {/*<UpgradePlan />*/}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
