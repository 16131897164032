import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { updateSort } from '../../../redux/actions/sort/actions';
import Selectbox from '../../Selectbox';

const sortBy = [
  { value: 'default', label: 'Default' },
  { value: 'nameAscending', label: 'Name ascending' },
  { value: 'nameDescending', label: 'Name descending' },
  { value: 'lowestprice', label: 'Lowest price' },
  { value: 'highestprice', label: 'Highest price' }
];

const Sort = ({ updateSort, sort }) => (
  <div className="sort">
    Sort by
    <Selectbox options={sortBy} handleOnChange={value => updateSort(value)} />
  </div>
);

Sort.propTypes = {
  updateSort: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  sort: state.sort.type
});

export default connect(
  mapStateToProps,
  { updateSort }
)(Sort);
