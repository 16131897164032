import React, { useState, useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RedeemIcon from '@material-ui/icons/Redeem';
import RestaurantIcon from '@material-ui/icons/Restaurant';

import MetricPanel from 'components/MetricPanel';
import { DateFilter, getDateRangeOptions } from 'components/DateFilter';
import { ManageMenuTable, EditMenu } from './components';

import { createOrUpdateMenuItem, getMenuItems, getMenuStats,
         enableMenuItem, disableMenuItem, deleteMenuItem
       } from 'api/admin';

const rangeOptions = getDateRangeOptions();
const defaultDateRange = rangeOptions[1]; // Today

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
}));

const ManageMenu = ({ user }) => {
  const classes = useStyles();

  const refEditForm = createRef();

  const [data, setData] = useState({ stats: {}, menuItems: [] });
  const [dateRange, setDateRange] = useState(defaultDateRange);

  const [openEditForm, setOpenEditForm] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
   fetchData();
  }, []);

  const onDateRangeChange = (range) => {
    setDateRange(range);
  }

  const handleEditFormOpen = () => {
    setOpenEditForm(true);
  };

  const handleEditFormClose = () => {
    refEditForm.current.onSetMenuItem(null);
    setOpenEditForm(false);
  };

  const handleUpdateMenuItem = (menuItem) => {
    handleEditFormOpen();
    refEditForm.current.onSetMenuItem(menuItem);
  };

  const handleEnableMenuItem = (menuItem) => {
    setRefreshing(true);

    enableMenuItem(menuItem.id)
    .then(() => {
      fetchData();
    })
    .catch(error => {
      console.log(`[ManageMenu.handleEnableMenuItem] ERROR: `, error);
      setRefreshing(false);
    })
  };

  const handleDisableMenuItem = (menuItem) => {
    setRefreshing(true);

    disableMenuItem(menuItem.id)
    .then(() => {
      fetchData();
    })
    .catch(error => {
      console.log(`[ManageMenu.handleDisableMenu] ERROR: `, error);
      setRefreshing(false);
    })
  };

  const handleDeleteMenuItem = (menuItem) => {
    setRefreshing(true);

    deleteMenuItem(menuItem.id)
    .then(() => {
      fetchData();
    })
    .catch(error => {
      console.log(`[ManageMenu.handleDeleteMenuItem] ERROR: `, error);
      setRefreshing(false);
    })
  };

  const handleCreateOrUpdateMenuItem = (menuItem) => {
    setRefreshing(true);

    createOrUpdateMenuItem(menuItem)
    .then(resultMenuItem => {
      console.log(`[handleCreateOrUpdateMenuItem] resultMenuItem: `, resultMenuItem);

      fetchData();
    })
    .catch(error => {
      console.log(`[ManageMenu.handleCreateOrUpdateMenuItem] ERROR: `, error);
      setRefreshing(false);
    })
  };


  //----------------------------------------------------------------------------
  // API
  //----------------------------------------------------------------------------

  const fetchData = () => {
    // https://stackoverflow.com/questions/37230555/get-with-query-string-with-fetch-in-react-native
    setRefreshing(true);

    getMenuStats(dateRange.startDate.format('YYYY-MM-DD'),
                          dateRange.endDate.format('YYYY-MM-DD'),
                          dateRange.stats.year,
                          dateRange.stats.month,
                          dateRange.stats.week,
                          dateRange.stats.day)
    .then(({ stats, menuItems }) => {
      console.log(`stats: `, stats);
      console.log(`menuItems: `, menuItems);

      setData({ stats, menuItems });

      setRefreshing(false);
    })
    .catch(error => {
      console.log(`[ManageMenu.fetchData] ERROR: `, error);
      setRefreshing(false);
    });
  }

  const stats = data.stats;
  const menuItems = data.menuItems;

  const orderQuantity = (stats.data && stats.data.orderQuantity) || 0;

  const foodQuantity = (stats.data && stats.data.foodQuantity) || 0;
  const drinkQuantity = (stats.data && stats.data.drinkQuantity) || 0;
  const dessertQuantity = (stats.data && stats.data.otherCount) || 0;

  const totalAmount = currencyFormatter.format(stats.data ? ((stats.data.totalAmount || 0)/100) : 0);

/*
  const hasDiscount = stats.data ? stats.data.hasDiscount : 0;
  const discountAmount = currencyFormatter.format(stats.data ? ((stats.data.discountAmount || 0)/100) : 0);
  const discountedSubTotalAmount = currencyFormatter.format(stats.data ? ((stats.data.discountedSubTotalAmount || 0)/100) : 0);
*/

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Backdrop className={classes.backdrop} open={refreshing} onClick={() => setRefreshing(false)}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <EditMenu ref={refEditForm}
                  open={openEditForm}
                  onClose={handleEditFormClose}
                  onSubmit={handleCreateOrUpdateMenuItem}
                  user={user} />
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <DateFilter title={'MANAGE MENU'} rangeOptions={rangeOptions} defaultDateRange={dateRange} onDateRangeChange={onDateRangeChange}/>
          </Grid>

          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
          <MetricPanel title="$$ ORDERS"
                 color="info"
                 icon={<AttachMoneyIcon className={classes.icon}/>}
                 value={totalAmount}
                 />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <MetricPanel title="# MEALS"
                   color="primary"
                   icon={<RedeemIcon className={classes.icon}/>}
                   value={foodQuantity}
                   />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <MetricPanel title="# DRINKS"
                   color="success"
                   icon={<AttachMoneyIcon className={classes.icon}/>}
                   value={drinkQuantity}
                   />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <MetricPanel title="# DESSERTS"
                   color="warning"
                   icon={<RestaurantIcon className={classes.icon}/>}
                   value={dessertQuantity}
                   />
          </Grid>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <div className={classes.row}>
              <span className={classes.spacer} />
              {/*
              <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button>
              */}
              <Button
                color="primary"
                variant="contained"
                onClick={handleEditFormOpen}
              >
                Add Menu Item
              </Button>
            </div>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <ManageMenuTable menuItems={data.menuItems}
                                  handleUpdateMenuItem={handleUpdateMenuItem}
                                  handleEnableMenuItem={handleEnableMenuItem}
                                  handleDisableMenuItem={handleDisableMenuItem}
                                  handleDeleteMenuItem={handleDeleteMenuItem}
                                  user={user}
                                  />
          </Grid>
        </Grid>

      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(
  mapStateToProps,
)(ManageMenu);
