import { query } from './helpers';

//------------------------------------------------------------------------------
// DISCOUNTS
//------------------------------------------------------------------------------

// get discount offer
export const getDiscountOffer = () => {
  return query('get', '/discounts/offer');
}

// apply order discount
export const applyOrderDiscount = (orderDetails, discountCode) => {
  //console.log(`[applyOrderDiscount] `, { orderDetails, discountCode });
  return query('put', '/discounts/apply', { orderDetails, discountCode });
}
