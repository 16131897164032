import { LOAD_CART, ADD_PRODUCT, REMOVE_PRODUCT, CHANGE_PRODUCT_QUANTITY, UPDATE_TIP, UPDATE_DISCOUNT } from '../../actions/cart/actionTypes';

const initialState = {
  products: [],
  tipAmount: 0,
  discountOffer: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_CART:
      return {
        ...state,
        products: action.payload
      };
    case ADD_PRODUCT:
      return {
        ...state,
        productToAdd: Object.assign({}, action.payload)
      };
    case REMOVE_PRODUCT:
      return {
        ...state,
        productToRemove: Object.assign({}, action.payload)
      };
    case CHANGE_PRODUCT_QUANTITY:
      return {
        ...state,
        productToChange: Object.assign({}, action.payload)
      };
    case UPDATE_TIP:
      return {
        ...state,
        tipAmount: action.payload
      };
    case UPDATE_DISCOUNT:
      return {
        ...state,
        discountOffer: action.payload
      };
    default:
      return state;
  }
}
