import { UPDATE_FILTER } from '../../actions/filters/actionTypes';

const initialState = {
  items: { type: 'food', categories: [] }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FILTER:
      return {
        ...state,
        items: action.payload
      };
    default:
      return state;
  }
}
