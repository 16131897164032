import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  FormGroup,
//  Grid,
  IconButton,
  Switch,
  Typography,
} from '@material-ui/core';

import BuildIcon from '@material-ui/icons/Build';
import BugReportIcon from '@material-ui/icons/BugReport';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';

import MaterialTable from 'material-table'

import { getInitials } from 'helpers';

//import moment from 'moment';
import moment from 'moment-timezone';
//https://stackoverflow.com/questions/18448347/how-to-create-time-in-a-specific-time-zone-with-moment-js
moment.tz.setDefault("America/Los_Angeles");

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

function Role({ user, onUpdateUserRole }) {
  const none = !user.staff && !user.admin && !user.superAdmin;
  const staff = user.staff && !user.admin && !user.superAdmin;
  const admin = user.admin && !user.superAdmin;
  const superAdmin = user.superAdmin;

  return (
    <ButtonGroup variant="contained" aria-label="contained primary button group">
      <Button color={ none && "secondary"} onClick={() => onUpdateUserRole(user, 'default')}>None</Button>
      <Button color={ staff && "secondary"} onClick={() => onUpdateUserRole(user, 'staff')}>Staff</Button>
      <Button color={ admin && "secondary"} onClick={() => onUpdateUserRole(user, 'admin')}>Admin</Button>
      <Button color={ superAdmin && "secondary"} onClick={() => onUpdateUserRole(user, 'superAdmin')}>Super</Button>
    </ButtonGroup>
  );
}

const hasError = (user) => {
  return !user.cid;
}

const UsersTable = props => {
  const { className, users, onUpdateUserRole, onRepairUser, ...rest } = props;

  const classes = useStyles();

  const [filters, setFilters] = useState({ none: true, staff: true, admin: true, superAdmin: true, cid: true });

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const filterUsers = (user) => {
    const none = !user.staff && !user.admin && !user.superAdmin;
    const staff = user.staff && !user.admin && !user.superAdmin;
    const admin = user.admin && !user.superAdmin;
    const superAdmin = user.superAdmin;

    if (filters.staff && staff) return true;
    if (filters.admin && admin) return true;
    if (filters.superAdmin && superAdmin) return true;

    if (!!filters.cid === !!user.cid) return true;

    return filters.none && none;
  }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <FormGroup row>
            <FormControlLabel
              control={<Switch checked={filters.none} onChange={handleFilterChange} name="none" />}
              label={<span style={{color: '#5cb85c'}}>NONE</span>}
            />
            <FormControlLabel
              control={<Switch checked={filters.staff} onChange={handleFilterChange} name="staff"/>}
              label={<span style={{color: '#428bca'}}>STAFF</span>}
            />
            <FormControlLabel
              control={<Switch checked={filters.admin} onChange={handleFilterChange} name="admin"/>}
              label={<span style={{color: '#d9534f'}}>ADMIN</span>}
            />
            <FormControlLabel
              control={<Switch checked={filters.superAdmin} onChange={handleFilterChange} name="superAdmin"/>}
              label={<span style={{color: '#d9534f'}}>SUPER</span>}
            />
            <FormControlLabel
              control={<Switch checked={filters.cid} onChange={handleFilterChange} name="cid"/>}
              label={<span style={{color: '#d9534f'}}>CID</span>}
            />
          </FormGroup>
        }
        title={'USERS'}
      />
      <Divider />
      <CardContent className={classes.content}>
        <MaterialTable
          options={{
            exportButton: true,
            exportFileName: `EatSimplified - Users`,
            filtering: true,
            search: true,
            pageSize: 400,
            pageSizeOptions: [25, 50, 100, 200, 400, 800, 1600],
            rowStyle: {
              borderBottom: "2px solid black"
            }
          }}
          data={users.filter(filterUsers)}
          title={''}
          columns={[
            { field: 'displayName', title: 'Name',
              render: customer =>
                <div className={classes.row}>
                  <Avatar
                    className={classes.avatar}
                    src={customer.photoURL}
                  >
                    {getInitials(customer.displayName || customer.name)}
                  </Avatar>
                  <Typography variant="body1">{customer.displayName || customer.name}</Typography>
                </div>
            },
            { field: 'email', title: 'Email',
              render: customer => <Typography variant="body2">{ customer.email }</Typography>
            },
            { field: 'cid', title: 'Customer Id',
              render: customer => <Typography variant="body2"><a target="_blank" rel="noopener noreferrer" href={`https://dashboard.stripe.com/customers/${customer.cid}`}>{ customer.cid }</a></Typography>
            },
            { field: 'metadata.creationTime', title: 'Join Date',
              filtering: false, searchable: false,
              render: customer => <Typography variant="body2">{customer.metadata && customer.metadata.creationTime && moment(customer.metadata.creationTime).format('ddd DD MMM YYYY, hh:mm A')}</Typography>
            },
            { field: 'metadata.lastSignInTime', title: 'Last Login',
              filtering: false, searchable: false,
              render: customer => <Typography variant="body2">{customer.metadata && customer.metadata.lastSignInTime && moment(customer.metadata.lastSignInTime).format('ddd DD MMM YYYY, hh:mm A')}</Typography>
            },
            { field: '', title: 'Role',
              filtering: false, searchable: false,
              render: customer => <Role user={customer} onUpdateUserRole={onUpdateUserRole}/>
            },
/*
            { field: '', title: 'Details',
              filtering: false, searchable: false,
              render: customer => {
                return Object.keys(customer).map(key =>
                  <Grid container spacing={1}>
                     <Grid item xs={6} sm={6}>
                       <Typography variant="body2" gutterBottom>
                         { key }
                       </Typography>
                     </Grid>
                     <Grid item xs={6} sm={6}>
                       <Typography variant="body2">
                         { JSON.stringify(customer[key]) }
                       </Typography>
                     </Grid>
                   </Grid>
                 );
               }
            },
            */
            { field: '', title: 'Action',
              render: customer =>
                <div className={classes.row}>
                  <IconButton size="small"
                              variant="contained"
                              style={{ backgroundColor: '#f0ad4e', color: 'white', marginRight: 5 }}
                              onClick={() => onRepairUser(customer, false)}>
                    <BuildIcon />
                  </IconButton>
                  <IconButton size="small"
                              variant="contained"
                              style={{ backgroundColor: '#5bc0de', color: 'white', marginRight: 5 }}
                              onClick={() => onRepairUser(customer, true)}>
                    <BugReportIcon />
                  </IconButton>
                </div>
              },
              { field: 'info', title: 'Info',
                render: customer =>
                  (
                    customer.info && <div className={classes.row}>
                      <WarningIcon />
                      <Typography variant="body2">{ customer.info }</Typography>
                    </div>
                  ) || (hasError(customer) && <ErrorIcon />)
              },
            ]}
          />
      </CardContent>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
