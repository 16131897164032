import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  Typography
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { StatusBullet } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  avatar: {
    marginRight: theme.spacing(2),
    borderRadius: 10
  },
}));

const statusColors = {
  delivered: 'success',
  pending: 'info',
  refunded: 'danger'
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const RecentOrders = props => {
  const { className, title, orders, ...rest } = props;

  const classes = useStyles();

  const renderMenuItems = (list) => {
    if (!list || list.length === 0) return <div/>

    return (
      <div>
      {
        list.map(item => {
          return <div key={item.sku} className={classes.nameContainer}>
            <Avatar
              className={classes.avatar}
              src={item.image}
            />
            <Typography variant="body1">{ item.title }</Typography>
            <Typography variant="body1">{ item.quantity }</Typography>
          </div>;
        })
      }
      </div>
    );
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
      /*
        action={
          <Button
            color="primary"
            size="small"
            variant="outlined"
          >
            New entry
          </Button>
        }
        */
        title={title}
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Order #</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Entrées</TableCell>
                  <TableCell>Desserts</TableCell>
                  <TableCell>Drinks</TableCell>
                  <TableCell>Delivery Time</TableCell>
                  <TableCell>Address</TableCell>
                  {/*<TableCell>Actions</TableCell>*/}
                  {/*<TableCell>Tip</TableCell>*/}
                  {/*<TableCell>Status</TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map(order => (
                  <TableRow
                    hover
                    key={order.orderId}
                  >
                    <TableCell>{order.date}</TableCell>
                    <TableCell>{order.orderId}</TableCell>
                    <TableCell>{ order.order.totalPrice }</TableCell>
                    <TableCell>{ renderMenuItems(order.order.foodList) }</TableCell>
                    <TableCell>{ renderMenuItems(order.order.otherList) }</TableCell>
                    <TableCell>{ renderMenuItems(order.order.drinkList) }</TableCell>
                    <TableCell>{ order.schedule.date } @ { moment(order.schedule.time, 'HH:mm').tz('America/Los_Angeles').format('h:mm a') }</TableCell>
                    <TableCell>{ order.delivery.fullAddress }</TableCell>
                    {/*
                    <TableCell>
                      <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                      >
                        Reorder
                      </Button>
                    </TableCell>
                    <TableCell>{ order.order.tip }</TableCell>
                    <TableCell>
                      <div className={classes.statusContainer}>
                        <StatusBullet
                          className={classes.status}
                          color={ statusColors[['delivered', 'pending', 'refunded'][Math.floor(Math.random() * 3)]] }
                          size="sm"
                        />
                        {order.status}
                      </div>
                    </TableCell>
                    */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
      {/*
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          View all <ArrowRightIcon />
        </Button>
        */}
      </CardActions>
    </Card>
  );
};

RecentOrders.propTypes = {
  className: PropTypes.string
};

export default RecentOrders;
