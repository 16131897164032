import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { firebaseAnalytics } from "components/firebase/firebase";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';

import { addProduct, removeProduct, changeProductQuantity } from '../../../../redux/actions/cart/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  description: {
    height: 50,
    overflow: 'hidden'
  },
  content: {
    padding: 8
  },
  price: {
  },
  iconContainer: {
    paddingTop: 10
  },
  icon: {
    width: 18,
  },
}));

const Product = ({ dispatch, product, openSnackbar, onConfirmDrinkingAge }) => {
  const classes = useStyles();

  product.quantity = 1;

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: product.currencyId
  });

  const defaultPrice = currencyFormatter.format(product.price);
  const bulkPrice = !!product.bulkAmount && currencyFormatter.format(product.bulkPrice);

  // bulk pricing
  //const pricing = !bulkPrice ? `${defaultPrice}` : `${defaultPrice} or ${bulkPrice} with any ${product.bulkAmount} entrées`;
  const pricing = `${defaultPrice}`;

  const spicy = product.spicy ? <img alt="spicy" className={classes.icon} src={require('assets/shopping/spicy.png')}/> : null;
  const vegetarian = product.vegetarian ? <img alt="vegetarian" className={classes.icon} src={require('assets/shopping/vegetarian.png')}/> : null;
  const alcohol = product.alcohol ? <img alt="alcohol" className={classes.icon} src={require('assets/shopping/alcohol.png')}/> : null;

  const headerIcons = <div className={classes.iconContainer}>{spicy}{vegetarian}{alcohol}</div>;

  const handleAddToCart = () => {
    firebaseAnalytics.logEvent('add_to_cart', {
      value: product.price,
      currency: product.currencyId,
      items: [product],
    });

    dispatch(addProduct(product));
    openSnackbar && openSnackbar(product);
  }

  const handleAddToCartPrompt = () => {
    if (product.alcohol){
      const onConfirmCallback = {
        callback: () => {
          console.log(`onConfirmPromise`);
          handleAddToCart();
        }
      };

      onConfirmDrinkingAge && onConfirmDrinkingAge(onConfirmCallback);
    }
    else{
      handleAddToCart();
    }
  }

  return (
    <Card
          className="shelf-item"
          style={{ margin: 5}}
          data-sku={product.sku}>
      <CardHeader
        titleTypographyProps={{noWrap:true}}
        title={ product.title }
        action={ headerIcons }
        /*
        subheader={pricing}
        avatar={ spicy }
        */
      />
      <CardMedia
        className={classes.media}
        //image={require(`../../../../assets/menu/${product.sku}.jpg`)}
        image={product.image}
        title={ product.title }
      />
      <CardContent className={classes.content}>
        <Typography className={classes.description} variant="body2" color="textSecondary" component="p">
          { product.description }
        </Typography>
      </CardContent>
      <CardContent className={classes.content}>
        <Typography className={classes.price} variant="h5" color="textPrimary" component="p">
          { pricing }
        </Typography>
      </CardContent>
      <CardActions
        className="shelf-item__buy-btn"
        onClick={handleAddToCartPrompt}
        >
        Add to cart
      </CardActions>
    </Card>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
  addProduct: PropTypes.func
};

export default connect()(Product);
