import React, { useState } from 'react';
import clsx from 'clsx';

import moment from 'moment-timezone';
//import moment from 'moment';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import DateMenu from './DateMenu';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
}));

const DateFilter = props => {
  const { className, title, rangeOptions, onDateRangeChange, defaultDateRange, ...rest } = props;

  const classes = useStyles();

  const [dateRange, setDateRange] = useState({});

  const handleDateRangeChange = (range) => {
    setDateRange(range);
    onDateRangeChange && onDateRangeChange(range);
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <DateMenu defaultDateRange={defaultDateRange} options={rangeOptions} onDateRangeChange={handleDateRangeChange} />
        }
        title={title}
      />
      <Divider />
        <CardContent className={classes.content}>
        </CardContent>
      <Divider />
    </Card>
  );
};

DateFilter.propTypes = {
  className: PropTypes.string
};

export default DateFilter;
