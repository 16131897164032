import { firebaseApp, applyCustomClaims, firebaseAnalytics } from "components/firebase/firebase";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const requestLogin = () => {
  return {
    type: LOGIN_REQUEST
  };
};

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    user
  };
};

export const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

export const logoutError = () => {
  return {
    type: LOGOUT_FAILURE
  };
};

export const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST
  };
};

export const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS
  };
};

export const loginError = (error) => {
  return {
    type: LOGIN_FAILURE,
    error: error
  };
};

export const loginUser = (email, password) => dispatch => {
  dispatch(requestLogin());
  firebaseApp
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(user => {
      dispatch(loginSuccess(user));
    })
    .catch(error => {
      //Do something with the error if you want!
      dispatch(loginError(error));
    });
};

export const logoutUser = () => dispatch => {
  dispatch(requestLogout());
  firebaseApp
    .auth()
    .signOut()
    .then(() => {
      dispatch(logoutSuccess());
    })
    .catch(error => {
      //Do something with the error if you want!
      dispatch(logoutError());
    });
};

export const verifyAuth = () => dispatch => {
  dispatch(verifyRequest());
  firebaseApp
    .auth()
    .onAuthStateChanged((authUser) => {
      if (authUser){
        applyCustomClaims(authUser)
        .then((user) => {
          if (user.disabled){
            logoutUser();
          }
          else{
            firebaseAnalytics.logEvent(`login`);

            dispatch(loginSuccess(user));
          }

          dispatch(verifySuccess());
        })
        .catch((err) => {
          dispatch(verifySuccess());
        });
      }
      else{
        dispatch(verifySuccess());
      }
    });
};
