import React from 'react';
import { makeStyles } from '@material-ui/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Grid from '@material-ui/core/Grid';

import Product from './Product';

const useStyles = makeStyles(theme => ({
  root: {
    //padding: theme.spacing(3)
  },
  content: {
    //marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const ProductList = ({ products, openSnackbar }) => {
  const classes = useStyles();

  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [onConfirmCallback, setOnConfirmCallback] = React.useState({ callback: null });

  const handleConfirmationClose = () => {
    setOpenConfirmation(false);
    setOnConfirmCallback({ callback: null });
  };

  const onClickConfirm = (onConfirm) => {
    onConfirmCallback && onConfirmCallback.callback && onConfirmCallback.callback();
    handleConfirmationClose();
  }

  const onConfirmDrinkingAge = (onConfirmCallback) => {
    setOnConfirmCallback(onConfirmCallback);
    setOpenConfirmation(true);
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
          //spacing={3}
        >
          {products.map(product => (
            <Grid
              item
              key={product.id}
              lg={3}
              md={6}
              xs={12}
            >
              <Product product={product} key={product.id} openSnackbar={openSnackbar} onConfirmDrinkingAge={onConfirmDrinkingAge}/>
            </Grid>
          ))}
        </Grid>
      </div>
      <Dialog
        open={openConfirmation}
        onClose={handleConfirmationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Legal Drinking Age</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you 21, and of legal drinking age?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="primary">
            No, I am not
          </Button>
          <Button onClick={onClickConfirm} color="primary" autoFocus>
            Yes, I am 21
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

/*
  return products.map(product => {
    return <Product product={product} key={product.id} />;
  });
  */
};

export default ProductList;
