import { query, expandQueryParams, getParam } from './helpers';

//------------------------------------------------------------------------------
// User Endpoints
//------------------------------------------------------------------------------

export const loginUser = () => {
 return query('put', `/users/login`);
}

export const getUserProfile = (uid) => {
 return query('get', `/users/${uid}`);
}

export const getUsers = (queryParams) => {
 return query('get', `/users${expandQueryParams(queryParams)}`);
}

export const updateUser = (uid, userData) => {
 return query('put', `/users/${uid}`, userData);
}

export const updateUserPhoto = (uid, photoData) => {
 return query('put', `/users/${uid}/photo`, photoData);
}

export const updateUserPlace = (uid, place) => {
 return query('put', `/users/${uid}/place`, place);
}

export const getUserOrders = (startDate, endDate, byOrderDate) => {
  const args = [];

  startDate && args.push(getParam(`startDate`, startDate));
  endDate && args.push(getParam(`endDate`, endDate));

  byOrderDate && args.push(getParam(`byOrderDate`, byOrderDate));

  const queryParams = args.length > 0 ? '?' + args.join('&') : '';

 return query('get', `/orders${queryParams}`);
}

//------------------------------------------------------------------------------
// Validation Endpoints
//------------------------------------------------------------------------------

export const isUniqueUsername = (username) => {
  return query('get', `/validate/username/${username}`);
};
