import React, { Fragment } from 'react';

import Modal from '@material-ui/core/Modal';

import Shelf from '../../components/Shelf';
import Filter from '../../components/Shelf/Filter';
import FloatCart from '../../components/FloatCart';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Hidden from '@material-ui/core/Hidden';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  cart: {
    width: 250,
  },
  fullCart: {
    width: 'auto',
  },  floatCart: {
    padding: theme.spacing(4),
    marginTop: 150,
    paddingTop: 150,
  },
  modal: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
//    backgroundColor: 'green'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
//    backgroundColor: 'green'
  },
  paper: {
  //  position: 'absolute',
    width: 400,
    height: 300,
    color: 'black',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    textAlign: 'center'
  },
}));

const Order = () => {
  const classes = useStyles();

  const [openCheckout, setOpenCheckout] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <div className={classes.root}>
      <Fragment>
        <main>
          <Filter />
          <Shelf openSnackbar={handleOpenSnackbar}/>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message={`Added to cart`}
            action={
              <React.Fragment>
                {/*
                <Button color="secondary" size="small" onClick={handleOpenCheckout}>
                  Open Cart
                </Button>
                */}
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </main>
      </Fragment>
    </div>
  );
};

export default Order;
