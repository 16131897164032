import { UPDATE_BILLING_SUMMARY,
         UPDATE_DELIVERY_SCHEDULE,
         UPDATE_DELIVERY_ADDRESS,
         UPDATE_BILLING_ADDRESS,
         UPDATE_PAYMENT_METHOD,
         UPDATE_PAYMENT_INTENT,
         UPDATE_PAYMENT_ORDER
       } from './actionTypes';

export const DEFAULT_DELIVERY_ADDRESS = { name: '', street: '', city: '', state: 'California', postalCode: '', country: 'US',
                                          email: '', phone: '', isBillingAddress: true };
export const DEFAULT_BILLING_ADDRESS = { name: '', street: '', city: '', state: 'California', postalCode: '', country: 'US',
                                         email: '', phone: ''};

export const getFullAddress = (address) => {
   const parts = [];
   address.street && parts.push(address.street);
   address.city && parts.push(address.city);
   address.state && parts.push(address.state);
   address.postalCode && parts.push(address.postalCode);
   address.country && parts.push(address.country);
   return parts.join(', ')
}

export const updateOrderDetails = orderDetails => dispatch => {
  dispatch({
    type: UPDATE_BILLING_SUMMARY,
    payload: orderDetails
  });
};

export const updateDeliveryAddress = deliveryAddress => dispatch => {
  dispatch({
    type: UPDATE_DELIVERY_ADDRESS,
    payload: {
      ...deliveryAddress,
      fullAddress: getFullAddress(deliveryAddress)
    }
  });
};

export const updateDeliverySchedule = deliverySchedule => dispatch => {
  dispatch({
    type: UPDATE_DELIVERY_SCHEDULE,
    payload: deliverySchedule
  });
};

export const updateBillingAddress = billingAddress => dispatch => {
  dispatch({
    type: UPDATE_BILLING_ADDRESS,
    payload: {
      ...billingAddress,
      fullAddress: getFullAddress(billingAddress)
    }
  });
};

export const updatePaymentMethod = paymentMethod => dispatch => {
  dispatch({
    type: UPDATE_PAYMENT_METHOD,
    payload: paymentMethod
  });
};

export const updatePaymentIntent = paymentIntent => dispatch => {
  dispatch({
    type: UPDATE_PAYMENT_INTENT,
    payload: paymentIntent
  });
};

export const updatePaymentOrder = paymentOrder => dispatch => {
  dispatch({
    type: UPDATE_PAYMENT_ORDER,
    payload: paymentOrder
  });
};
