import React, { useState, useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import {
  Backdrop,
  Button,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
} from '@material-ui/core';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RedeemIcon from '@material-ui/icons/Redeem';
import RestaurantIcon from '@material-ui/icons/Restaurant';

import MetricPanel from 'components/MetricPanel';
import { DateFilter, getDateRangeOptions } from 'components/DateFilter';
import { ManageDiscountsTable, EditForm } from './components';

import { createOrUpdateDiscount, getDiscountOffers, getDiscountOfferStats,
         enableDiscountOffer, disableDiscountOffer, deleteDiscountOffer,
         getOrderStats
       } from 'api/admin';

import { getQueryParams } from 'api/helpers';

const rangeOptions = getDateRangeOptions();
const defaultDateRange = rangeOptions[1]; // Today

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
}));

const ManageDiscounts = ({ user }) => {
  const classes = useStyles();

  const refEditForm = createRef();

  const [data, setData] = useState({ stats: {}, discountOffers: [] });
  const [dateRange, setDateRange] = useState(defaultDateRange);

  const [openEditForm, setOpenEditForm] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [listQuery, setListQuery] = useState({ filterKey: 'name', filterValue: null, page: 0 });

  const [listData, setListData] = useState({
    data: [],
    length: 0,
    sections: [],
    canFetchMore: true,
  });

  useEffect(() => {
   fetchData();
  }, [listQuery]);

  const onDateRangeChange = (range) => {
    setDateRange(range);
  }

  const handleEditFormOpen = () => {
    setOpenEditForm(true);
  };

  const handleEditFormClose = () => {
    refEditForm.current.onSetDiscount(null);
    setOpenEditForm(false);
  };

  const handleUpdateDiscount = (discount) => {
    handleEditFormOpen();
    refEditForm.current.onSetDiscount(discount);
  };

  const handleEnableDiscount = (discount) => {
    setRefreshing(true);

    enableDiscountOffer(discount.id)
    .then(() => {
      fetchData();
    })
    .catch(error => {
      console.log(`[ManageDiscounts.handleEnableDiscount] ERROR: `, error);
      setRefreshing(false);
    })
  };

  const handleDisableDiscount = (discount) => {
    setRefreshing(true);

    disableDiscountOffer(discount.id)
    .then(() => {
      fetchData();
    })
    .catch(error => {
      console.log(`[ManageDiscounts.handleDisableDiscount] ERROR: `, error);
      setRefreshing(false);
    })
  };

  const handleDeleteDiscount = (discount) => {
    setRefreshing(true);

    deleteDiscountOffer(discount.id)
    .then(() => {
      fetchData();
    })
    .catch(error => {
      console.log(`[ManageDiscounts.handleDeleteDiscount] ERROR: `, error);
      setRefreshing(false);
    })
  };

  const handleCreateOrUpdateDiscount = (discountOffer) => {
    setRefreshing(true);

    createOrUpdateDiscount(discountOffer)
    .then(resultDiscountOffer => {
      //console.log(`[handleCreateOrUpdateDiscount] resultDiscountOffer: `, resultDiscountOffer);

      fetchData();
    })
    .catch(error => {
      console.log(`[ManageDiscounts.handleCreateOrUpdateDiscount] ERROR: `, error);
      setRefreshing(false);
    })
  };


  //----------------------------------------------------------------------------
  // API
  //----------------------------------------------------------------------------

  const fetchData = () => {
    // https://stackoverflow.com/questions/37230555/get-with-query-string-with-fetch-in-react-native
    setRefreshing(true);

    getDiscountOfferStats(dateRange.startDate.format('YYYY-MM-DD'),
                          dateRange.endDate.format('YYYY-MM-DD'),
                          dateRange.stats.year,
                          dateRange.stats.month,
                          dateRange.stats.week,
                          dateRange.stats.day)
    .then(({ stats, discountOffers }) => {
      //console.log(`stats: `, stats);
      //console.log(`discountOffers: `, discountOffers);

      setData({ stats, discountOffers });

      setRefreshing(false);
    })
    .catch(error => {
      console.log(`[ManageDiscounts.fetchData] ERROR: `, error);
      setRefreshing(false);
    });
  }

  const stats = data.stats;
  const discountOffers = data.discountOffers;

  const isFree = stats.data ? stats.data.isFree : 0;
  const hasDiscount = stats.data ? stats.data.hasDiscount : 0;
  const discountAmount = currencyFormatter.format(stats.data ? ((stats.data.discountAmount || 0)/100) : 0);
  const discountedSubTotalAmount = currencyFormatter.format(stats.data ? ((stats.data.discountedSubTotalAmount || 0)/100) : 0);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Backdrop className={classes.backdrop} open={refreshing} onClick={() => setRefreshing(false)}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <EditForm ref={refEditForm}
                  open={openEditForm}
                  onClose={handleEditFormClose}
                  onSubmit={handleCreateOrUpdateDiscount}
                  user={user} />
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <DateFilter title={'MANAGE DISCOUNTS'} rangeOptions={rangeOptions} defaultDateRange={dateRange} onDateRangeChange={onDateRangeChange}/>
          </Grid>

          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <MetricPanel title="# DISCOUNTED"
                   color="warning"
                   icon={<RestaurantIcon className={classes.icon}/>}
                   value={hasDiscount}
                   />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
          <MetricPanel title="$$ DISCOUNTED"
                 color="info"
                 icon={<AttachMoneyIcon className={classes.icon}/>}
                 value={discountAmount}
                 />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <MetricPanel title="# FREE"
                   color="primary"
                   icon={<RedeemIcon className={classes.icon}/>}
                   value={isFree}
                   />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <MetricPanel title="$$ REVENUE"
                   color="success"
                   icon={<AttachMoneyIcon className={classes.icon}/>}
                   value={discountedSubTotalAmount}
                   />
          </Grid>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <div className={classes.row}>
              <span className={classes.spacer} />
              {/*
              <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button>
              */}
              <Button
                color="primary"
                variant="contained"
                onClick={handleEditFormOpen}
              >
                Add Discount Offer
              </Button>
            </div>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <ManageDiscountsTable discounts={data.discountOffers}
                                  handleUpdateDiscount={handleUpdateDiscount}
                                  handleEnableDiscount={handleEnableDiscount}
                                  handleDisableDiscount={handleDisableDiscount}
                                  handleDeleteDiscount={handleDeleteDiscount}
                                  user={user}
                                  />
          </Grid>
        </Grid>

      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(
  mapStateToProps,
)(ManageDiscounts);
