import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  billingAddressSection: {
    marginBottom: 15,
  },
  billingAddressExpanded: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  paymentSection: {
    paddingBottom: 15,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DiscountOffer({ user, orderDetails, isFree, hasDiscount }) {
  const classes = useStyles();

  const discountCode = orderDetails.discountCode;
  const discountSponsor = orderDetails.discountSponsor;

  return (
    <React.Fragment>
      <Card className={classes.billingAddressSection}>
        <CardHeader
//          title={ discountCode }
          subheader={`DISCOUNT CODE: ${discountCode}`}
        />
        <CardContent>
          { isFree && `This complimentary meal is a gift from ${discountSponsor}.` }
          { !isFree && hasDiscount && `This discounted meal is a gift from ${discountSponsor}.` }
        </CardContent>
      </Card>
      <Card className={classes.paymentSection}>
        <CardHeader
          subheader="DISCLAIMER"
        />
        <CardContent>
          Meals are available for you and your loved ones (limited to yourself and those within your residence, please).
          Users suspected of abusing this privilege will be blocked from EatSimplified.
          We reserve the right to cancel this offer anytime, without fulfillment.
          <br/>
          <br/>
          <b>By clicking next you agree to the terms of this discount offer.</b>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
