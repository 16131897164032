import React from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Button, Divider, SwipeableDrawer, Typography } from '@material-ui/core';

import { Cart, DeliverySchedule, Tip, Promo, OrderSummary } from './components';

import { getOrderDetails } from 'redux/util';
import { updateOrderDetails } from 'redux/actions/checkout/actions';

import { firebaseAnalytics } from "components/firebase/firebase";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
//    padding: theme.spacing(2),
    alignItems: 'center',
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  actions: {
    //paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    //padding: theme.spacing(1, 2),
  },
  checkoutButton: {
    marginRight: theme.spacing(1),
  },
  donateButton: {
    marginLeft: theme.spacing(1),
  },
}));

const DrawerCart = ({ dispatch,
                      open, onOpen, onClose, onToggle, onOpenCheckout,
                      variant, className,
                      user,
                      cartProducts, cartTotal, tipAmount, discountOffer,
                      newProduct, productToRemove, productToChange,
                      ...rest }) => {
  const classes = useStyles();

  const orderDetails = getOrderDetails(cartProducts, tipAmount, cartTotal, discountOffer);

  const handleCheckout = (donation) => {
    const { productQuantity } = cartTotal;

    if (!productQuantity) {
      alert('Add some menu items.');
    } else {
      dispatch(updateOrderDetails({
        ...orderDetails,
        donation
      }));

      onOpenCheckout();

      firebaseAnalytics.logEvent('begin_checkout', {
        value: orderDetails.totalAmount,
        currency: orderDetails.currencyId,
//        items: [...orderDetails.foodList, ...orderDetails.drinkList, ...orderDetails.otherList]
      });
    }
  }

  // https://material-ui.com/api/swipeable-drawer/
  // https://material-ui.com/api/drawer/
  return (
    <SwipeableDrawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      variant={variant}
      /*
      ModalProps={{
        BackdropComponent: <Backdrop open={true} />,
        BackdropProps: { open: true, invisible: false },
        onBackdropClick: onToggle }
      }
      disableSwipeToOpen={false}
      elevation={16}
      */
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Typography
          align="center"
          gutterBottom
          variant="h6"
        >
          CART
        </Typography>

        <Divider className={classes.divider} />

        <Cart
          className={classes.nav}
          cartProducts={cartProducts}
          cartTotal={cartTotal}
          newProduct={newProduct}
          productToRemove={productToRemove}
          productToChange={productToChange}
        />

        <Divider className={classes.divider} />

        <Tip/>

        <Promo orderDetails={orderDetails} />

        <OrderSummary orderDetails={orderDetails} />

        <DeliverySchedule discountOffer={discountOffer} />

        <Divider className={classes.divider} />

        <div className={classes.actions}>
          <Button
            color="primary"
            className={classes.checkoutButton}
            component="a"
            onClick={() => handleCheckout(false)}
            variant="contained"
          >
            Checkout
          </Button>
          {/*
          <Button
            color="default"
            className={classes.donateButton}
            component="a"
            onClick={() => handleCheckout(true)}
            variant="outlined"
          >
            Donate
          </Button>
          */}
        </div>


      </div>
    </SwipeableDrawer>
  );
};

DrawerCart.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

const mapStateToProps = ({ cart, total }) => ({
  cartProducts: cart.products,
  tipAmount: cart.tipAmount,
  discountOffer: cart.discountOffer,
  newProduct: cart.productToAdd,
  productToRemove: cart.productToRemove,
  productToChange: cart.productToChange,
  cartTotal: total.data
});

export default connect(
  mapStateToProps
  //{ loadCart, updateCart, removeProduct, changeProductQuantity }
)(DrawerCart);
