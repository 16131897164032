import React from 'react';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
    borderRadius: 15,
  },
  cardActions: {
    justifyContent: 'center',
    margin: theme.spacing(0)
  },
}));

const StripeCheckout = ({ amount, className, onPayment, ...rest }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    onPayment && onPayment(error, paymentMethod);
  };

  /*
  <form onSubmit={handleSubmit}>
    <button type="submit" disabled={!stripe}>
      Pay { amount }
    </button>
  </form>
  */

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <CardElement />
      </CardContent>
      <Divider />
      <CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={handleSubmit}
          disabled={!stripe}
        >
          Pay
        </Button>
      </CardActions>
      </CardContent>
    </Card>
  );
};

export default StripeCheckout;
