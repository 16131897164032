import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const isoWeekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
}));

const ManageMenuTable = props => {
  const { className, menuItems,
          handleUpdateMenuItem, handleEnableMenuItem, handleDisableMenuItem, handleDeleteMenuItem,
          user,
          ...rest } = props;

  const classes = useStyles();

  const getStatus = (menuItem) => {
    if (menuItem.disabled) return "DISABLED";

    return "ACTIVE"
  }

  const renderText = (text) => {
    return (
      <Typography variant="body2" color="textSecondary">
        { text }
      </Typography>
    );
  }

  const getDaysAvailable = (menuItem) => {
    const days = isoWeekdays.filter((isoWeekday, index) => {
      return !!menuItem.daysAvailable && !!menuItem.daysAvailable[`${index + 1}`];
    });

    return <span>
      { days.map((day, i) => <Typography key={i} variant="body2" color="textSecondary"><span>{day}<br/></span></Typography>) }
      </span>;
  }

  const getCategories = (menuItem) => {
    const categories = menuItem.categories;

    return <span>
      { categories && categories.map((category, i) => <Typography key={i} variant="body2" color="textSecondary"><span>{category}<br/></span></Typography>) }
      </span>;
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell style={{ width: 150 }}>Description</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Categories</TableCell>
                  <TableCell>Days Available</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  menuItems.map(menuItem => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={menuItem.id}
                  >
                    <TableCell>{ renderText(getStatus(menuItem)) }</TableCell>
                    <TableCell>{ renderText(menuItem.sku) }</TableCell>
                    <TableCell>{ renderText(menuItem.title) }</TableCell>
                    <TableCell>{ renderText(menuItem.description) }</TableCell>
                    <TableCell>{ renderText(menuItem.type) } </TableCell>
                    <TableCell>{ getCategories(menuItem) }</TableCell>
                    <TableCell>{ getDaysAvailable(menuItem) }</TableCell>
                    <TableCell>
                      <IconButton size="small"
                                  variant="contained"
                                  style={{ backgroundColor: '#5bc0de', color: 'white', marginRight: 5 }}
                                  onClick={() => handleUpdateMenuItem(menuItem)}>
                        <EditIcon />
                      </IconButton>

                      <IconButton size="small"
                                  variant="contained"
                                  style={{ backgroundColor: '#f0ad4e', color: 'white', marginRight: 5 }}
                                  onClick={() => menuItem.disabled ? handleEnableMenuItem(menuItem) : handleDisableMenuItem(menuItem)}>
                        { menuItem.disabled ? <PlayArrowIcon /> : <BlockIcon /> }

                      </IconButton>

                      { user && user.superAdmin &&
                        <IconButton size="small"
                                  variant="contained"
                                  style={{ backgroundColor: '#d9534f', color: 'white' }}
                                  onClick={() => handleDeleteMenuItem(menuItem)}>
                            <DeleteForeverIcon />
                        </IconButton>
                      }

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

ManageMenuTable.propTypes = {
  className: PropTypes.string,
  menuItems: PropTypes.array.isRequired
};

export default ManageMenuTable;
