import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

// https://material-ui.com/customization/palette/
// color: primary, secondary, error, warning, info, success
const useStyles = (color) => makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette[color].main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

const MetricPanel = props => {
  const { className, title, color, icon, value, delta, ...rest } = props;

  const classes = useStyles(color)();

  const directionIcons = {
    up: <ArrowUpwardIcon className={classes.differenceIcon} />,
    down: <ArrowDownwardIcon className={classes.differenceIcon} />,
    flat: <TrendingFlatIcon className={classes.differenceIcon} />
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              { title }
            </Typography>
            <Typography
              color="inherit"
              variant="h3"
            >
              { value }
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              { icon }
            </Avatar>
          </Grid>
        </Grid>
        {
          delta && (
            <div className={classes.difference}>
              { directionIcons[delta.direction] }
              <Typography
                className={classes.differenceValue}
                variant="body2"
              >
                { delta.value }
              </Typography>
              <Typography
                className={classes.caption}
                variant="caption"
              >
                { delta.caption }
              </Typography>
            </div>)
        }
      </CardContent>
    </Card>
  );
};

MetricPanel.propTypes = {
  className: PropTypes.string
};

export default MetricPanel;
