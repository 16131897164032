import React, { useState, forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import CloseIcon from '@material-ui/icons/Close';

import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';

import ActivityButton from 'components/ActivityButton';
import { DatePicker, TimePicker } from "@material-ui/pickers";

//import moment from 'moment';
import moment from 'moment-timezone';
//https://stackoverflow.com/questions/18448347/how-to-create-time-in-a-specific-time-zone-with-moment-js
moment.tz.setDefault("America/Los_Angeles");

const defaultOffer = {
   code: '',
   sponsor: '',
   label: '',
   discountPercent: 100,
   limit: 1,
   unlimited: true,
   amountLimit: 75,
   amountUnlimited: true,
   foodLimit: 1,
   foodUnlimited: true,
   drinkLimit: 1,
   drinkUnlimited: true,
   otherLimit: 1,
   otherUnlimited: true,
   startDate: '',
   endDate: '',
   indefinite: false,
   deliveryDays: { '6': true },
   deliveryTimes: [],
   cutoffDays: 1,
   isEvent: true,
};

const isoWeekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{ paddingTop: 15 }}>
      {'Copyright © '}
      <Link color="inherit" href="https://eatsimplified.com/">
        EatSimplified by Élyse Restaurant
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  dialog: {
//    flex: 'display',
//    width: 'auto',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const EditForm = forwardRef(({ open, onClose, user, onSubmit }, ref) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [discount, setDiscount] = useState(defaultOffer);

  const handleOnDialogClose = (...args) => {
    onClose(...args);
    setEditMode(false);
  }

  const onSetDiscount = (newDiscount) => {
    setDiscount({ ...defaultOffer, ...newDiscount } );
    setEditMode(true);
  }

  // https://reactjs.org/docs/forwarding-refs.html
  ref.current = { onSetDiscount };

  const isNumberField = (name) => {
    return name == "limit" || name == "discountPercent" || name == "amountLimit"
           || name == "foodLimit" || name == "drinkLimit" || name == "otherLimit"
           || name == "cutoffDays";
  }

  const isUppercase = (name) => {
    return name == "code";
  }

  const handleTextChange = (e) => {
    const name = e.target.name;
    const value = isNumberField(name) ? Number(e.target.value || 0) : e.target.value;

    const newDiscount = {
      ...discount,
      [name]: isUppercase(name) ? value.toUpperCase() : value
    };

    setDiscount(newDiscount);
  };

  const handleUnlimitedChecked = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    const newDiscount = {
      ...discount,
      [name]: !!checked
    };

    setDiscount(newDiscount);
  };

  const handleIndefiniteChecked = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    const newDiscount = {
      ...discount,
      [name]: !!checked
    };

    setDiscount(newDiscount);
  };

  const handleIsEventChecked = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    const newDiscount = {
      ...discount,
      [name]: !!checked
    };

    setDiscount(newDiscount);
  };

  const handleDeliveryDayChecked = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    const deliveryDays = discount.deliveryDays || {};
    deliveryDays[name] = !!checked;

    //console.log(`${name}: `, deliveryDays);

    const newDiscount = {
      ...discount,
      deliveryDays
    };

    setDiscount(newDiscount);
  };

  const handleStartDateChange = (date) => {
    const newDiscount = {
      ...discount,
      startDate: date.format(`YYYY-MM-DD`)
    };

    setDiscount(newDiscount);
  };

  const handleEndDateChange = (date) => {
    const newDiscount = {
      ...discount,
      endDate: date.format(`YYYY-MM-DD`)
    };

    setDiscount(newDiscount);
  };

  const handleTimeChange = (date) => {
    const newDiscount = {
      ...discount,
      deliveryTimes: date ? [date.format(`HH:mm`)] : []
    };

    setDiscount(newDiscount);
  };

  const handleTimeClear = () => {
    const newDiscount = {
      ...discount,
      deliveryTimes: []
    };

    setDiscount(newDiscount);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(discount);
    handleOnDialogClose();
  }

  return (
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleOnDialogClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
      {/* https://stackoverflow.com/a/51404769 */}
      <DialogTitle disableTypography id="scroll-dialog-title" className={classes.dialogTitle}>
        <h2> { editMode ? "UPDATE DISCOUNT" : "CREATE DISCOUNT" } </h2>
        <IconButton size="small" onClick={handleOnDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Typography variant="h5" gutterBottom>
        </Typography>
        <Typography variant="subtitle1">
        </Typography>
        <Grid container
              spacing={2}
              //wrap="wrap"
              >
          <Grid item xs={6} sm={6}>
            <TextField
              required
              id="code"
              name="code"
              label="Discount Code"
              fullWidth
              autoComplete="code"
              value={discount.code}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              required
              id="sponsor"
              name="sponsor"
              label="Sponsor"
              fullWidth
              autoComplete="sponsor"
              value={discount.sponsor || ''}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="label"
              name="label"
              label="Description"
              fullWidth
              autoComplete="label"
              value={discount.label}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="discountPercent"
              name="discountPercent"
              label="Discount Percent"
              fullWidth
              autoComplete="discountPercent"
              value={discount.discountPercent}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
          <br/>
          </Grid>
          <Grid item xs={10} sm={10}>
            <TextField
              required
              id="limit"
              name="limit"
              label="Total Item Limit"
              fullWidth
              autoComplete="limit"
              value={discount.limit}
              disabled={!!discount.unlimited}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <FormControlLabel
              control={
                <Checkbox checked={!!discount.unlimited}
                          onChange={handleUnlimitedChecked}
                          id="unlimited"
                          name="unlimited" />
              }
              label={
                <Typography variant="caption" gutterBottom>
                  Unlimited
                </Typography>
              }
              //labelPlacement="top"
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <TextField
              required
              id="amountLimit"
              name="amountLimit"
              label="Order Amount Limit"
              fullWidth
              autoComplete="amountLimit"
              value={discount.amountLimit}
              disabled={!!discount.amountUnlimited}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <FormControlLabel
              control={
                <Checkbox checked={!!discount.amountUnlimited}
                          onChange={handleUnlimitedChecked}
                          id="amountUnlimited"
                          name="amountUnlimited" />
              }
              label={
                <Typography variant="caption" gutterBottom>
                  Unlimited
                </Typography>
              }
              //labelPlacement="top"
            />
          </Grid>
          <Grid item xs={2} sm={2}/>
          <Grid item xs={3} sm={3}>
            <TextField
              required
              id="foodLimit"
              name="foodLimit"
              label="Entrée Limit"
              fullWidth
              autoComplete="foodLimit"
              value={discount.foodLimit || ''}
              disabled={!!discount.foodUnlimited}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <FormControlLabel
              control={
                <Checkbox checked={!!discount.foodUnlimited}
                          onChange={handleUnlimitedChecked}
                          id="foodUnlimited"
                          name="foodUnlimited" />
              }
              label={
                <Typography variant="caption" gutterBottom>
                  Unlimited
                </Typography>
              }
              //labelPlacement="top"
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <TextField
              required
              id="drinkLimit"
              name="drinkLimit"
              label="Drink Limit"
              fullWidth
              autoComplete="drinkLimit"
              value={discount.drinkLimit || ''}
              disabled={!!discount.drinkUnlimited}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <FormControlLabel
              control={
                <Checkbox checked={!!discount.drinkUnlimited}
                          onChange={handleUnlimitedChecked}
                          id="drinkUnlimited"
                          name="drinkUnlimited" />
              }
              label={
                <Typography variant="caption" gutterBottom>
                  Unlimited
                </Typography>
              }
              //labelPlacement="top"
            />
          </Grid>
          <Grid item xs={2} sm={2}/>
          <Grid item xs={3} sm={3}>
            <TextField
              required
              id="otherLimit"
              name="otherLimit"
              label="Dessert Limit"
              fullWidth
              autoComplete="otherLimit"
              value={discount.otherLimit || ''}
              disabled={!!discount.otherUnlimited}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <FormControlLabel
              control={
                <Checkbox checked={!!discount.otherUnlimited}
                          onChange={handleUnlimitedChecked}
                          id="otherUnlimited"
                          name="otherUnlimited" />
              }
              label={
                <Typography variant="caption" gutterBottom>
                  Unlimited
                </Typography>
              }
              //labelPlacement="top"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <br/>
          </Grid>
          <Grid item xs={4} sm={4}>
            <FormControl className={classes.formControl}>
              <DatePicker
                className={classes.deliveryDate}
                format="YYYY-MM-DD"
                label="Start Date"
                value={moment(discount.startDate, 'YYYY-MM-DD').toDate()}
                onChange={handleStartDateChange}
                showTodayButton
                autoOk
                margin="none"
                fullWidth
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={4}>
            <FormControl className={classes.formControl}>
              <DatePicker
                className={classes.deliveryDate}
                format="YYYY-MM-DD"
                label="End Date"
                value={moment(discount.endDate, 'YYYY-MM-DD').toDate()}
                onChange={handleEndDateChange}
                showTodayButton
                autoOk
                margin="none"
                fullWidth
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} sm={2}>
            <FormControlLabel
              control={
                <Checkbox checked={!!discount.isEvent}
                          onChange={handleIsEventChecked}
                          id="isEvent"
                          name="isEvent" />
              }
              label="Event"
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <FormControlLabel
              control={
                <Checkbox checked={discount.indefinite}
                          onChange={handleIndefiniteChecked}
                          id="indefinite"
                          name="indefinite" />
              }
              label="Indefinite"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <br/>
          </Grid>
          <Grid item xs={3} sm={3}>
            Delivery Days:
          </Grid>
          <Grid item xs={9} sm={9}>
            {
              isoWeekdays.map((day, index) => {
                const isoWeekday = `${index + 1}`;

                return <FormControlLabel
                  key={isoWeekday}
                  control={
                    <Checkbox checked={!!discount.deliveryDays && !!discount.deliveryDays[isoWeekday]}
                              onChange={handleDeliveryDayChecked}
                              id={isoWeekday}
                              name={isoWeekday} />
                  }
                  label={day}
                />;
              })
            }
          </Grid>
          <Grid item xs={3} sm={3}>
            Delivery Time:
          </Grid>
          <Grid item xs={2} sm={2}>
            <FormControl className={classes.formControl}>
              <TimePicker
                format="h:mm A"
                //label="Delivery Time"
                value={discount.deliveryTimes && discount.deliveryTimes.length > 0 ? moment(discount.deliveryTimes[0], 'HH:mm').toDate() : null}
                onChange={handleTimeChange}
                margin="none"
                fullWidth
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1}>
            { discount.deliveryTimes && discount.deliveryTimes.length > 0 &&
              <IconButton size="small" edge="start" style={{color: "#d9534f"}} onClick={handleTimeClear}>
                <CloseIcon/>
              </IconButton>
            }
          </Grid>
          <Grid item xs={1} sm={1}/>
          <Grid item xs={3} sm={3}>
            Cutoff Days:
          </Grid>
          <Grid item xs={2} sm={2}>
            <FormControl className={classes.formControl}>
              <TextField
                required
                id="cutoffDays"
                name="cutoffDays"
                //label="Delivery Cutoff Days"
                fullWidth
                autoComplete="cutoffDays"
                value={discount.cutoffDays || 0}
                onChange={handleTextChange}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button onClick={handleOnDialogClose} className={classes.button}>
            Cancel
          </Button>
          <ActivityButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.button}
            disabled={loading}
            loading={loading}
          >
            { editMode ? "UPDATE" : "CREATE" }
          </ActivityButton>
        </div>
        <Copyright />
      </DialogContent>
    </Dialog>
  );
});

export default EditForm;
