import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  billingAddressSection: {
    marginBottom: 15,
  },
  billingAddressExpanded: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  paymentSection: {
    paddingBottom: 15,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const cardElementOptions = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const cleanValue = (name, value) => {
  if (name == 'email'){
    return (value && value.trim()) || '';
  }

  return value;
}

export default function PaymentForm({ user, donation, matchesDeliveryAddress,
                                      hasDiscount, orderDetails,
                                      billingAddress, paymentMethod,
                                      onAddressChange, onPaymentChange,
                                      CardElement }) {
  const classes = useStyles();

  const [billingAddressExpanded, setBillingAddressExpanded] = React.useState(!matchesDeliveryAddress);

  const handleBillingAddressExpandClick = () => {
    setBillingAddressExpanded(!billingAddressExpanded);
  };

  const handleAddressChange = (e) => {
    const name = e.target.name;
    const value = cleanValue(name, e.target.value);

    const newBillingAddress = {
      ...billingAddress,
      [name]: value
    };

    onAddressChange && onAddressChange(newBillingAddress)
  };

  const discountCode = orderDetails.discountCode;
  const discountSponsor = orderDetails.discountSponsor;

  return (
    <React.Fragment>
      <Card className={classes.billingAddressSection}>
        <CardHeader
          title="BILLING ADDRESS"
          subheader={billingAddress.fullAddress}
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: billingAddressExpanded,
              })}
              size="small"
              onClick={handleBillingAddressExpandClick}>
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <CardContent>
          { hasDiscount && `This discounted meal is a gift from ${discountSponsor}.` }
        </CardContent>
        <Collapse in={billingAddressExpanded} timeout="auto" unmountOnExit className={classes.billingAddressExpanded}>
          <Grid container
                spacing={2}
                //wrap="wrap"
                >
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                fullWidth
                autoComplete="name"
                value={billingAddress.name}
                onChange={handleAddressChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="street"
                name="street"
                label="Street"
                fullWidth
                autoComplete="billing street"
                value={billingAddress.street}
                onChange={handleAddressChange}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                id="city"
                name="city"
                label="city"
                fullWidth
                autoComplete="billing city"
                value={billingAddress.city}
                onChange={handleAddressChange}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                id="state"
                name="state"
                label="state"
                fullWidth
                autoComplete="billing state"
                value={billingAddress.state}
                onChange={handleAddressChange}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                id="postalCode"
                name="postalCode"
                label="Zip / Postal code"
                fullWidth
                autoComplete="billing postal-code"
                value={billingAddress.postalCode}
                onChange={handleAddressChange}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                id="country"
                name="country"
                label="Country"
                fullWidth
                autoComplete="billing country"
                value={billingAddress.country}
                onChange={handleAddressChange}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                fullWidth
                autoComplete="billing email"
                value={billingAddress.email}
                onChange={handleAddressChange}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                id="phone"
                name="phone"
                label="Phone"
                fullWidth
                autoComplete="billing phone"
                value={billingAddress.phone}
                onChange={handleAddressChange}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Card>
      <Card className={classes.paymentSection}>
        <CardHeader
          title="PAYMENT METHOD"
/*          subheader=""
          action={
            <IconButton size="small" onClick={handleBillingAddressExpandClick}>
              <ExpandMoreIcon />
            </IconButton>
          }*/
        />
        <CardContent>
          <CardElement options={cardElementOptions}/>
          {
            hasDiscount &&
            <CardContent>
              <br/>
              Meals are available for you and your loved ones (limited to yourself and those within your residence, please).
              Users suspected of abusing this privilege will be blocked from EatSimplified.
              We reserve the right to cancel this offer anytime, without fulfillment.
              <br/>
              <br/>
              <b>By clicking next you agree to the terms of this discount offer.</b>
            </CardContent>
          }
        </CardContent>

      </Card>
    </React.Fragment>
  );
}
