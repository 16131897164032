import { getIdToken } from '../components/firebase/firebase';
import { serverBaseUrl, clientVersion } from '../config';

//------------------------------------------------------------------------------
// Helper
//------------------------------------------------------------------------------

export const getQueryParams = (filterVal, orderBy, page, pageSize) => {
  const queryParams = {};

  if (filterVal) queryParams.filter = { key: 'name', value: filterVal };
  if (orderBy) queryParams.order = { by: orderBy, direction: 'asc' };
  if (page >= 0) queryParams.page = { page: page, limit: pageSize };

  return queryParams;
}

export const expandQueryParams = (queryParams) => {
  if (!queryParams) return '';

  const { filter, order, page } = queryParams;
  const args = [];

  filter && args.push(getParam(`filterKey`, filter.key));
  filter && args.push(getParam(`filterValue`, filter.value));

  order && args.push(getParam(`orderBy`, order.by));
  order && args.push(getParam(`orderDirection`, order.direction));

  page && args.push(getParam(`page`, page.page));
  page && args.push(getParam(`limit`, page.limit));

  return args.length > 0 ? '?' + args.join('&') : '';
}

export const getParam = (name, value) => {
  return name && (value || typeof value == 'number') ? `${encodeURIComponent(name)}=${encodeURIComponent(value)}` : '';
}

export const publicQuery = (method, endpoint, body) => {
  return query(method, endpoint, body, true);
}

// https://github.com/axios/axios
export const query = (method, endpoint, body, unauthenticated) => {
  //console.log(`endpoint:: ${endpoint}`);
  //const idToken = localStorage.token;

  return getIdToken()
  .then(idToken => {
    if (!idToken) return Promise.reject("Not authenticated!");

    const config = {
      method: method,
      headers: unauthenticated ? {} : {
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json',
        'X-Client-Version': clientVersion
      }
    };
    if (!!body) config.body = JSON.stringify(body);

    return fetch(serverBaseUrl + endpoint, config)
      .then(response =>
        response.text().then(text => ({ text, response }))
      ).then(({ text, response }) => {
        if (!response.ok) {
          return Promise.reject(text);
        }

        try{
          const json = JSON.parse(text);

          if (response.status < 200 || response.status >= 300){
            return Promise.reject(text);
          }

          return json;
        }
        catch (err){
          return Promise.reject(text);
        }
      });
  });
}
