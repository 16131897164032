import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  Typography
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { StatusBullet } from 'components';

import MaterialTable from 'material-table'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2),
    borderRadius: 10
  },
}));

const statusColors = {
  delivered: 'success',
  pending: 'info',
  refunded: 'danger'
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const pageSizeOptions = [25, 50, 100, 200, 400, 800, 1600];

const getDefaultPageSize = (items, options) => options.reduceRight((accumulator, current) => {
  return (current >= items.length) ? current : accumulator;
}, options[options.length - 1]);

const OrderSummary = props => {
  const { className, title, orders, ...rest } = props;

  const classes = useStyles();

  const [defaultPageSize, setDefaultPageSize] = useState(getDefaultPageSize(orders, pageSizeOptions));

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={title}
      />
      <Divider />
      <CardContent className={classes.content}>
        <MaterialTable
          options={{
            exportButton: true,
            exportFileName: `EatSimplified - Order Summary`,
            filtering: true,
            search: true,
            pageSize: defaultPageSize,
            pageSizeOptions: pageSizeOptions,
            rowStyle: {
              borderBottom: "2px solid black"
            }
          }}
          //data={orders.filter(filterOrders)}
          data={orders}
          title={''}
          columns={[
            { field: 'title', title: 'Item',
              render: order => <div className={classes.nameContainer}>
                <Avatar
                  className={classes.avatar}
                  src={order.image}
                />
                <Typography variant="body1">{ order.title }</Typography>
              </div>
            },
            { field: 'quantity', title: 'Quantity',
              render: order => <Typography variant="body2">{ order.quantity }</Typography>
            },
            { field: 'amount', title: 'Revenue',
              render: order => <Typography variant="body2">{ currencyFormatter.format((order.amount || 0)/100) }</Typography>
            }
          ]}
        />
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

OrderSummary.propTypes = {
  className: PropTypes.string
};

export default OrderSummary;
