//import moment from 'moment';
import moment from 'moment-timezone';
//https://stackoverflow.com/questions/18448347/how-to-create-time-in-a-specific-time-zone-with-moment-js
moment.tz.setDefault("America/Los_Angeles");

const formatOptions = {
  year: 'YYYY',
  month: 'MM',
  week: 'ww',
  day: 'DD'
}

const now = () => moment();

export const getDateRange = (startDate, endDate, label, statOptions=[], divider) => {
  const stats = {};
  statOptions && statOptions.map((option) => {
    stats[option] = startDate.format(formatOptions[option]);
  });

  return {
    startDate,
    endDate: endDate || startDate,
    stats,
    label,
    divider
  };
}

export const getDateRangeOptions = () => [
  getDateRange(now().subtract(1, 'day'), null, 'YESTERDAY', ['year', 'month', 'day']),
  getDateRange(now(), null, 'TODAY', ['year', 'month', 'day']),
  getDateRange(now().add(1, 'day'), null, 'TOMORROW', ['year', 'month', 'day'], true),

  getDateRange(now().subtract(1, 'week').startOf('week'), now().subtract(1, 'week').endOf('week'), 'LAST WEEK', ['year', 'week']),
  getDateRange(now().startOf('week'), now().endOf('week'), 'THIS WEEK', ['year', 'week']),
  getDateRange(now().add(1, 'week').startOf('week'), now().add(1, 'week').endOf('week'), 'NEXT WEEK', ['year', 'week'], true),

  getDateRange(now().subtract(1, 'month').startOf('month'), now().subtract(1, 'month').endOf('month'), 'LAST MONTH', ['year', 'month']),
  getDateRange(now().startOf('month'), now().endOf('month'), 'THIS MONTH', ['year', 'month']),
  getDateRange(now().add(1, 'month').startOf('month'), now().add(1, 'month').endOf('month'), 'NEXT MONTH', ['year', 'month'], true),

  getDateRange( now().startOf('year'), now(), 'YEAR-TO-DATE', ['year']),
  getDateRange( now().startOf('year'), now().endOf('year'), 'THIS YEAR', ['year']),
  getDateRange( now().subtract(1, 'year').startOf('year'), now().subtract(1, 'year').endOf('year'), 'LAST YEAR', ['year']),
];
