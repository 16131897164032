import React, { useState, forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import CloseIcon from '@material-ui/icons/Close';

import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';

import ActivityButton from 'components/ActivityButton';
import { DatePicker, TimePicker } from "@material-ui/pickers";

//import moment from 'moment';
import moment from 'moment-timezone';
//https://stackoverflow.com/questions/18448347/how-to-create-time-in-a-specific-time-zone-with-moment-js
moment.tz.setDefault("America/Los_Angeles");

const defaultMenuItem = {
   id: '',
   disabled: false,
   rank: 0,
   sku: '',
   title: '',
   description: '',
   image: '',
   type: 'food',
   categories: ['food'],
   price: 16.50,
   daysAvailable: { '1': true, '2': true, '3': true, '4': true, '5': true },
   currencyId: 'USD',
   currencyFormat: '$',
   bulkAmount: 5,
   bulkPrice: 12.95,
   isFreeShipping: true
};

const categories = ['food', 'appetizer', 'drinks', 'beef', 'pork', 'chicken', 'crab', 'egg', 'tofu', 'seafood', 'veggie', 'soup', 'dessert'];
const isoWeekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{ paddingTop: 15 }}>
      {'Copyright © '}
      <Link color="inherit" href="https://eatsimplified.com/">
        EatSimplified by Élyse Restaurant
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  dialog: {
//    flex: 'display',
//    width: 'auto',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const EditMenu = forwardRef(({ open, onClose, user, onSubmit }, ref) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [menuItem, setMenuItem] = useState(defaultMenuItem);

  const handleOnDialogClose = (...args) => {
    onClose(...args);
    setEditMode(false);
  }

  const onSetMenuItem = (newMenuItem) => {
    setMenuItem({ ...defaultMenuItem, ...newMenuItem } );
    setEditMode(true);
  }

  // https://reactjs.org/docs/forwarding-refs.html
  ref.current = { onSetMenuItem };

  const isNumberField = (name) => {
    return name == "rank" || name == "price" || name == "bulkPrice" || name == "bulkAmount";
  }

  const isUppercase = (name) => {
    return name == "sku";
  }

  const handleTextChange = (e) => {
    const name = e.target.name;
    const value = isNumberField(name) ? Number(e.target.value || 0) : e.target.value;

    const newMenuItem = {
      ...menuItem,
      [name]: isUppercase(name) ? value.toUpperCase() : value
    };

    setMenuItem(newMenuItem);
  };

  const handleChoice = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    console.log(`[handleChoice] ${name}: ${value}`);

    const newMenuItem = {
      ...menuItem,
      [name]: value
    };

    setMenuItem(newMenuItem);
  };

  const handleChecked = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    //console.log(`[handleChecked] ${name}: ${checked}`);

    const newMenuItem = {
      ...menuItem,
      [name]: !!checked
    };

    setMenuItem(newMenuItem);
  };

  const handleCategoriesChecked = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    const index = menuItem.categories.indexOf(name);

    if (!!checked){
      (index < 0) && menuItem.categories.push(name);
    }
    else{
      menuItem.categories = menuItem.categories.filter((category) => category !== name);
    }

    //console.log(`${name}: `, menuItem.daysAvailable);

    const newMenuItem = {
      ...menuItem,
      categories: menuItem.categories
    };

    setMenuItem(newMenuItem);
  };

  const handleDayAvailableChecked = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    const daysAvailable = menuItem.daysAvailable || {};
    daysAvailable[name] = !!checked;

    console.log(`${name}: `, daysAvailable);

    const newMenuItem = {
      ...menuItem,
      daysAvailable
    };

    setMenuItem(newMenuItem);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(menuItem);
    handleOnDialogClose();
  }

  return (
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleOnDialogClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
      {/* https://stackoverflow.com/a/51404769 */}
      <DialogTitle disableTypography id="scroll-dialog-title" className={classes.dialogTitle}>
        <h2> { editMode ? "UPDATE MENU ITEM" : "CREATE MENU ITEM" } </h2>
        <IconButton size="small" onClick={handleOnDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Typography variant="h5" gutterBottom>
        </Typography>
        <Typography variant="subtitle1">
        </Typography>
        <Grid container
              spacing={2}
              //wrap="wrap"
              >
          <Grid item xs={2} sm={2}>
            <TextField
              required
              id="rank"
              name="rank"
              label="Rank"
              fullWidth
              autoComplete="rank"
              value={menuItem.rank}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <TextField
              required
              id="sku"
              name="sku"
              label="sku"
              fullWidth
              autoComplete="sku"
              value={menuItem.sku}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <TextField
              required
              id="price"
              name="price"
              label="Price"
              fullWidth
              autoComplete="price"
              value={menuItem.price}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="title"
              name="title"
              label="Title"
              fullWidth
              autoComplete="title"
              value={menuItem.title}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="description"
              name="description"
              label="Description"
              fullWidth
              autoComplete="description"
              value={menuItem.description}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="image"
              name="image"
              label="Image"
              fullWidth
              autoComplete="image"
              value={menuItem.image}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <br/>
          </Grid>
          <Grid item xs={3} sm={3}>
            Type:
          </Grid>
          <Grid item xs={9} sm={9}>
            {
              categories.map((category, index) => {
                return <FormControlLabel
                  key={category}
                  control={
                    <Radio checked={menuItem.type  === category}
                              onChange={handleChoice}
                              value={category}
                              id="type"
                              name="type" />
                  }
                  label={category}
                />;
              })
            }
          </Grid>
          <Grid item xs={3} sm={3}>
            Categories:
          </Grid>
          <Grid item xs={9} sm={9}>
            {
              categories.map((category, index) => {
                return <FormControlLabel
                  key={category}
                  control={
                    <Checkbox checked={!!menuItem.categories && (menuItem.categories.indexOf(category) >= 0)}
                              onChange={handleCategoriesChecked}
                              id={category}
                              name={category} />
                  }
                  label={category}
                />;
              })
            }
          </Grid>
          <Grid item xs={12} sm={12}>
            <br/>
          </Grid>
          <Grid item xs={3} sm={3}>
            Days Available:
          </Grid>
          <Grid item xs={9} sm={9}>
            {
              isoWeekdays.map((day, index) => {
                const isoWeekday = `${index + 1}`;

                return <FormControlLabel
                  key={isoWeekday}
                  control={
                    <Checkbox checked={!!menuItem.daysAvailable && !!menuItem.daysAvailable[isoWeekday]}
                              onChange={handleDayAvailableChecked}
                              id={isoWeekday}
                              name={isoWeekday} />
                  }
                  label={day}
                />;
              })
            }
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button onClick={handleOnDialogClose} className={classes.button}>
            Cancel
          </Button>
          <ActivityButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.button}
            disabled={loading}
            loading={loading}
          >
            { editMode ? "UPDATE" : "CREATE" }
          </ActivityButton>
        </div>
        <Copyright />
      </DialogContent>
    </Dialog>
  );
});

export default EditMenu;
