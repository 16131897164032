import { combineReducers } from "redux";
import auth from "./auth";
import shelf from './shelf/reducer';
import cart from './cart/reducer';
import checkout from './checkout/reducer';
import total from './total/reducer';
import filters from './filters/reducer';
import sort from './sort/reducer';

export default combineReducers({ auth, shelf, cart, checkout, total, filters, sort });
