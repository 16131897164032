import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    title: 'Consulting',
    plan: 'TRIAL',
    planIcon: 'timer',
    planColor: 'green',
    description:
      'Sign up for a free chat about our consulting services.',
    imageUrl: './images/products/product_1.png',
    reviews: '594',
    updatedAt: '27/03/2019'
  },
  {
    id: uuid(),
    title: 'Insights',
    plan: 'BRONZE PLAN',
    planIcon: 'timer',
    planColor: 'brown',
    description:
      'Understand audiences by trends, geo, etc.',
    imageUrl: './images/products/product_2.png',
    reviews: '625',
    createdAt: '31/03/2019'
  },
  {
    id: uuid(),
    title: 'Reach',
    plan: 'SILVER PLAN',
    planIcon: 'flare',
    planColor: 'silver',
    description:
      'Drive Awareness & Retention.',
    imageUrl: './images/products/product_3.png',
    reviews: '857',
    createdAt: '03/04/2019'
  },
  {
    id: uuid(),
    title: 'Acquistion',
    plan: 'GOLD PLAN',
    planIcon: 'people',
    planColor: 'gold',
    description:
      'Grow your users.',
    imageUrl: './images/products/product_4.png',
    reviews: '406',
    createdAt: '04/04/2019'
  },
  {
    id: uuid(),
    title: 'Viral',
    plan: 'PLATINUM PLAN',
    planIcon: 'share',
    planColor: 'blue',
    description:
      'Platinum: Turn your users into advocates.',
    imageUrl: './images/products/product_5.png',
    reviews: '835',
    createdAt: '04/04/2019'
  },
  {
    id: uuid(),
    title: 'The Situation Room',
    plan: 'VIBRANIUM PLAN',
    planIcon: 'speed',
    planColor: 'purple',
    description:
      'Artificial Intelligence: Let the machines do the work.',
    imageUrl: './images/products/product_6.png',
    reviews: '835',
    createdAt: '04/04/2019'
  }
];
