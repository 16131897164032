import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';

import { updateDeliverySchedule } from 'redux/actions/checkout/actions';
import { isWeekend, isTodayAfterNoon, dateLabel, shouldDisableDate, nextDeliveryDate, getDefaultDeliveryTime } from 'redux/util';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import EventIcon from '@material-ui/icons/Event';

import { DatePicker } from "@material-ui/pickers";

//import moment from 'moment';
import moment from 'moment-timezone';
//https://stackoverflow.com/questions/18448347/how-to-create-time-in-a-specific-time-zone-with-moment-js
moment.tz.setDefault("America/Los_Angeles");

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
//    marginLeft: theme.spacing(1),
//    marginRight: theme.spacing(1),
  },
  deliverySchedule: {
    marginTop: theme.spacing(1),
    display: 'flex',
//    justifyContent:'space-between',
//    justifyContent:'stretch',
//    alignItems: 'space-between',
  },
  formControl: {
    margin: theme.spacing(1),
//    minWidth: 120,
  },
  deliveryDate: {
    marginTop: theme.spacing(2),
    width: 130
  },
  deliveryTime: {
    marginTop: theme.spacing(2),
    //width: 90
  },
  orderCutoff: {
    color: '#000000CC',
    backgroundColor: 'gold',
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 5,
    textAlign: 'center'
  },
  orderCutoffDiscount: {
    color: '#000000CC',
    backgroundColor: 'cyan',
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 5,
    textAlign: 'center'
  }
}));

const defaultDeliveryTimes = ['17:30', '19:00'];

const DeliverySchedule = ({ dispatch, deliverySchedule, discountOffer, className, user, ...rest }) => {
  const classes = useStyles();

  const isEvent = discountOffer && discountOffer.isEvent;
  const deliveryDays = discountOffer && discountOffer.deliveryDays;
  const deliveryTimes = discountOffer && discountOffer.deliveryTimes && discountOffer.deliveryTimes.length > 0 ? discountOffer.deliveryTimes : defaultDeliveryTimes;
  const minDeliveryDate = nextDeliveryDate(deliveryDays, discountOffer).toDate();

  const [selectedDate, setSelectedDate] = React.useState(minDeliveryDate);
  const [selectedTime, setSelectedTime] = React.useState(getDefaultDeliveryTime(discountOffer));

  useEffect(() => {
    setSelectedDate(nextDeliveryDate(deliveryDays, discountOffer).toDate());
    setSelectedTime(getDefaultDeliveryTime(discountOffer));
  }, [discountOffer])

  useEffect(() => {
    dispatch(updateDeliverySchedule({ date: selectedDate, time: selectedTime }));
  }, [selectedDate, selectedTime])

  const handleDateChange = (date) => {
    setSelectedDate(date.toDate());
  };

  const handleTimeChange = (e) => {
    const time = e.target.value;
    setSelectedTime(time);
  };

  const dateTimeLabel = (date: DateIOType, invalidLabel: string)  => {
    return dateLabel(moment(date));
  }

  const handleShouldDisableDate = (calendarDate: DateIOType)  => {
    return shouldDisableDate(calendarDate, deliveryDays, discountOffer);
  }

  const getDeliveryTimeOptions = () => {
    //console.log(`isEvent: ${isEvent} `, deliveryTimes);

    return (
      <Select
        label="Time"
        labelId="delivery-time-label"
        id="delivery-time"
        value={selectedTime}
        onChange={handleTimeChange}
        displayEmpty
        margin="dense"
        fullWidth
        className={classes.deliveryTime}
      >
        {
          deliveryTimes.map((deliveryTime, i) => <MenuItem key={i} value={deliveryTime}>{moment(deliveryTime, 'HH:mm').format('h:mm A')}</MenuItem>)
        }
      </Select>
    );
  }

  // https://material-ui-pickers.dev/demo/datepicker

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <FormHelperText className={isEvent ? classes.orderCutoffDiscount : classes.orderCutoff}>
        <b>{ isEvent ? `ORDER MUST BE COMPLETED A DAY BEFORE DELIVERY BY 12PM` : `ORDER MUST BE MADE BY 12PM FOR SAME DAY DELIVERY` }</b>
      </FormHelperText>
      <Grid container justify="space-between" className={classes.deliverySchedule}>
        <Grid item lg={7} md={7} xl={7} xs={7} sm={7}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="delivery-date-label">
              Delivery Date
            </InputLabel>
            <DatePicker
              className={classes.deliveryDate}
              //openTo="year"
              format="yyyy-MM-dd"
              //label="Delivery Date"
              //views={["year", "month", "date"]}
              value={selectedDate}
              onChange={handleDateChange}
              //showTodayButton
              minDate={minDeliveryDate}
              //disablePast
              autoOk
              labelFunc={dateTimeLabel}
              shouldDisableDate={handleShouldDisableDate}
              margin="none"
              fullWidth
              size="small"
            />
          </FormControl>
        </Grid>
        <Grid item lg={5} md={5} xl={5} xs={5} sm={5}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="delivery-time-label">
              Time
            </InputLabel>
            { getDeliveryTimeOptions() }
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

DeliverySchedule.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

const mapStateToProps = ({ checkout }) => ({
  deliverySchedule: checkout.deliverySchedule,
});

export default connect(
  mapStateToProps,
)(DeliverySchedule);
