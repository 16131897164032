import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import UsersToolbar from 'components/UsersToolbar';
import { UserDiscountsTable } from './components';

import { getUsers, getDiscountOffers, grantUserDiscount } from 'api/admin';
import { getQueryParams } from 'api/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const UserDiscounts = () => {
  const classes = useStyles();

  const [refreshing, setRefreshing] = useState(false);
  const [discounts, setDiscounts] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [listQuery, setListQuery] = useState({ filterKey: 'name', filterValue: null, page: 0 });

  const [listData, setListData] = useState({
    data: [],
    length: 0,
    sections: [],
    canFetchMore: true,
  });

  useEffect(() => {
   fetchData();
  }, [listQuery]);

  //----------------------------------------------------------------------------
  // API
  //----------------------------------------------------------------------------

  const fetchData = () => {
    // https://stackoverflow.com/questions/37230555/get-with-query-string-with-fetch-in-react-native
    setRefreshing(true);

    const queryParams = getQueryParams(listQuery.filterValue,
                                       listQuery.filterKey,
                                       listQuery.page, rowsPerPage);

    getDiscountOffers()
    .then(discountOffers => {
      //console.log(`discountOffers: `, discountOffers);

      getUsers(queryParams)
      .then(users => {

        setDiscounts(discountOffers);

        const data = (listQuery.page > 0 ? listData.data.concat(users) : users);
        const length = data.length;
        const canFetchMore = !(length < rowsPerPage || length % rowsPerPage != 0);

        setListData({ data, length, canFetchMore });

        setRefreshing(false);
      })
      .catch(error => {
        console.log(`[UserDiscounts.fetchData] ERROR: `, error);
        setRefreshing(false);
      });
    });
  }

  const onUpdateDiscount = (user, discount) => {
    setRefreshing(true);

    grantUserDiscount && grantUserDiscount(user.uid, discount.code)
    .then(updatedUser => {
      // replace
      const index = listData.data.indexOf(user);

      if (index !== -1) {
        listData.data[index] = updatedUser;
      }

      setListData({ ...listData });
      setRefreshing(false);
    })
    .catch(error => {
      console.log(`[UserDiscounts.onUpdateDiscount] ERROR: `, error);
      setRefreshing(false);
    })
  }

  return (
    <div className={classes.root}>
      <UsersToolbar />
      <div className={classes.content}>
        <Backdrop className={classes.backdrop} open={refreshing} onClick={() => setRefreshing(false)}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <UserDiscountsTable users={listData.data} onUpdateDiscount={onUpdateDiscount} discounts={discounts}/>
      </div>
    </div>
  );
};

export default UserDiscounts;
