import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';

import { getDeliverySchedule } from 'redux/util';

import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  orderSummarySection: {
    marginBottom: 15,
  },
  orderSummaryExpanded: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  instructionsSection: {
    marginBottom: 15,
  },
  instructionsExpanded: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const DELIVERY_MESSAGE = 'Delivery instructions or personalized message';
const DONATION_MESSAGE = 'Personalized donation message';

export default function Review({ donation, orderDetails, deliveryAddress, paymentMethod, deliverySchedule, onOrderDetailsChange }) {
  const classes = useStyles();

  const [orderSummaryExpanded, setOrderSummaryExpanded] = React.useState(false);
  const [instructionsExpanded, setInstructionsExpanded] = React.useState(donation);

  const handleOrderSummaryExpandClick = () => {
    setOrderSummaryExpanded(!orderSummaryExpanded);
  };

  const handleInstructionsExpandClick = () => {
    setInstructionsExpanded(!instructionsExpanded);
  };

  const handleInstructionsChange = (e) => {
    const instructions = e.target.value || '';

    const newOrderDetails = {
      ...orderDetails,
      instructions
    };

    onOrderDetailsChange && onOrderDetailsChange(newOrderDetails)
  };

  return (
    <React.Fragment>
      <Card className={classes.orderSummarySection}>
        <CardHeader
          title={
            <Typography variant="h6" gutterBottom>
              Order summary
            </Typography>}
          subheader={
            <div>
              { orderDetails && orderDetails.description }
              <br/>
              <br/>
              { `We look forward to delivering a meal for you and your loved ones to enjoy on ${getDeliverySchedule(deliverySchedule).dateTime}` }
            </div>
          }
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: orderSummaryExpanded,
              })}
              size="small"
              onClick={handleOrderSummaryExpandClick}>
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse in={orderSummaryExpanded} timeout="auto" unmountOnExit className={classes.orderSummaryExpanded}>
          <List disablePadding spacing={2}>
            {orderDetails && orderDetails.foodList && orderDetails.foodList.map((food) => (
              <ListItem className={classes.listItem} key={food.id}>
                <ListItemText primary={`${food.title}`} secondary={food.description} />
                <Typography variant="body2">{food.quantity}</Typography>
              </ListItem>
            ))}
            {orderDetails && orderDetails.drinkList && orderDetails.drinkList.map((drink) => (
              <ListItem className={classes.listItem} key={drink.id}>
                <ListItemText primary={`${drink.title}`} secondary={drink.description} />
                <Typography variant="body2">{drink.quantity}</Typography>
              </ListItem>
            ))}
            {orderDetails && orderDetails.otherList && orderDetails.otherList.map((item) => (
              <ListItem className={classes.listItem} key={item.id}>
                <ListItemText primary={`${item.title}`} secondary={item.description} />
                <Typography variant="body2">{item.quantity}</Typography>
              </ListItem>
            ))}
            <Divider/>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Total" />
              <Typography variant="subtitle1" className={classes.total}>
                { orderDetails && orderDetails.totalPrice }
              </Typography>
            </ListItem>
          </List>
        </Collapse>
      </Card>
      <Divider/>
      <Card className={classes.instructionsSection}>
        <CardHeader
          title={
            <Typography variant="h6" gutterBottom>
              { donation ? DONATION_MESSAGE : DELIVERY_MESSAGE}
            </Typography>}
          subheader={orderDetails && orderDetails.instructions}
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: instructionsExpanded,
              })}
              size="small"
              onClick={handleInstructionsExpandClick}>
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse in={instructionsExpanded} timeout="auto" unmountOnExit className={classes.instructionsExpanded}>
          <TextField
            id="instructions"
            name="instructions"
            //label={ donation ? DONATION_MESSAGE : DELIVERY_MESSAGE}
            multiline
            rows={4}
            rowsMax={8}
            style={{ margin: 8 }}
            placeholder={ donation ? 'Enter a personal message to be delivered to your donation recipient on a custom card.' :
                                     'Enter optional delivery instructions or personalized message.'}
            helperText={ donation ? DONATION_MESSAGE : DELIVERY_MESSAGE}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
//            variant="filled"
            value={orderDetails && orderDetails.instructions}
            onChange={handleInstructionsChange}
          />
        </Collapse>
      </Card>
      <Divider/>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Delivery
          </Typography>
          <Typography gutterBottom>{ deliveryAddress.name}</Typography>
          <Typography gutterBottom>{ deliveryAddress.fullAddress }</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Payment details
          </Typography>
          { !!paymentMethod && (
              <Grid container>
                <Grid item xs={6}>
                  <Typography gutterBottom>Card type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{paymentMethod.card.brand}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography gutterBottom>Card holder</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{paymentMethod.billing_details.name}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography gutterBottom>Card number</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{`xxxx-xxxx-xxxx-${paymentMethod.card.last4}`}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography gutterBottom>Expiry date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{`${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}</Typography>
                </Grid>
              </Grid>
            ) }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
