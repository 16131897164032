import React from 'react';
import { connect } from "react-redux";

import { firebaseAnalytics } from "components/firebase/firebase";

import { createPaymentIntent, confirmPaymentOrder } from '../../api/orders';
import { updateDeliveryAddress, updateBillingAddress,
         updatePaymentMethod, updatePaymentIntent, updatePaymentOrder,
         updateOrderDetails} from 'redux/actions/checkout/actions';

import { updateCart } from 'redux/actions/total/actions';
import { loadCart, updateTip, updateDiscount } from 'redux/actions/cart/actions';

import { DEFAULT_BILLING_ADDRESS } from 'redux/actions/checkout/actions';

import { getDeliverySchedule } from 'redux/util';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import DiscountOffer from './DiscountOffer';
import Review from './Review';

import ActivityButton from '../ActivityButton';

import {loadStripe} from '@stripe/stripe-js';
import StripeCheckout from '../StripeCheckout';
import {
  Elements,
} from '@stripe/react-stripe-js';

import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

//import moment from 'moment';
import moment from 'moment-timezone';
//https://stackoverflow.com/questions/18448347/how-to-create-time-in-a-specific-time-zone-with-moment-js
moment.tz.setDefault("America/Los_Angeles");

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{ paddingTop: 15 }}>
      {'Copyright © '}
      <Link color="inherit" href="https://eatsimplified.com/">
        EatSimplified by Élyse Restaurant
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  dialog: {
//    flex: 'display',
//    width: 'auto',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const Checkout = ({ dispatch, open, onClose, user,
                    orderDetails,
                    deliveryAddress, deliverySchedule,
                    billingAddress,
                    paymentMethod, paymentIntent, paymentOrder}) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const isFree = !!(orderDetails && orderDetails.isFree && orderDetails.totalAmount === 0);
  const hasDiscount = !!(orderDetails && orderDetails.hasDiscount);
  const steps = isFree ? ['Delivery address', 'Discount Offer', 'Review your order'] : ['Delivery address', 'Payment details', 'Review your order'];

  const [activeStep, setActiveStep] = React.useState(0);
  const [nextButtonDisabled, setNextButtonDisabled] = React.useState(false);


  const handleOnDialogClose = (...args) => {
    setActiveStep(0);
    onClose(...args);
  }

  const clearCart = () => {
    dispatch(updateCart([]));
    dispatch(loadCart([]));
    dispatch(updateTip(0));
    dispatch(updateOrderDetails({}));
    dispatch(updateDiscount(null));
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AddressForm user={user}
                            donation={(orderDetails && orderDetails.donation) || false}
                            deliveryAddress={deliveryAddress}
                            onChange={onDeliveryAddressChange}/>;
      case 1:
        return isFree ?
        <DiscountOffer user={user}
                       donation={(orderDetails && orderDetails.donation) || false}
                       orderDetails={orderDetails}
                       isFree={isFree}
                       hasDiscount={hasDiscount}/>
                              :
        <PaymentForm user={user}
                            donation={(orderDetails && orderDetails.donation) || false}
                            orderDetails={orderDetails}
                            hasDiscount={hasDiscount}
                            matchesDeliveryAddress={(deliveryAddress && deliveryAddress.isBillingAddress) || false}
                            billingAddress={billingAddress}
                            paymentMethod={paymentMethod}
                            onAddressChange={onBillingAddressChange}
                            onPaymentChange={onPaymentMethodChange}
                            CardElement={CardElement}/>;
      case 2:
        return <Review orderDetails={orderDetails}
                       donation={(orderDetails && orderDetails.donation) || false}
                       deliveryAddress={deliveryAddress}
                       paymentMethod={paymentMethod}
                       paymentIntent={paymentIntent}
                       deliverySchedule={deliverySchedule}
                       onOrderDetailsChange={onOrderDetailsChange}
                      />;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    orderDetails && firebaseAnalytics.logEvent('checkout_progress', {
      value: orderDetails.totalAmount,
      currency: orderDetails.currencyId,
      checkout_step: activeStep,
//      items: [...orderDetails.foodList, ...orderDetails.drinkList, ...orderDetails.otherList],
    });

    switch (activeStep) {
      case 0:
        //console.log(`[handleNext] isFree: ${isFree} | activeStep: ${activeStep}`);
        const nextStep = 1; // isFree ? 2 : 1;
        setActiveStep(activeStep + nextStep);
        break;
      case 1:
        setNextButtonDisabled(true);

        getPaymentMethod()
        .then(newPaymentMethod => {
          //console.log(`[handleNext] ${activeStep} | newPaymentMethod: `, newPaymentMethod);
          onPaymentMethodChange && onPaymentMethodChange(newPaymentMethod);

          return createPaymentIntent(newPaymentMethod, orderDetails, deliveryAddress, billingAddress)
          .then(newPaymentIntent => {
            //console.log(`[createPaymentIntent] `, newPaymentIntent);
            onPaymentIntentChange && onPaymentIntentChange(newPaymentIntent);

            setActiveStep(activeStep + 1);
            setNextButtonDisabled(false);
          })
        })
        .catch(error => {
          console.log(`[Checkout.getPaymentMethod] error: `, error);

          alert(error.message);
          setNextButtonDisabled(false);
        })
        break;
      case 2:
        setNextButtonDisabled(true);

        const confirmation = isFree ? () => Promise.resolve(paymentIntent) : confirmCardPayment;

        confirmation()
        .then(newPaymentIntent => {
          //console.log(`[handleNext] ${activeStep} | newPaymentIntent: `, newPaymentIntent);

          return confirmPaymentOrder(newPaymentIntent, paymentMethod, orderDetails, billingAddress, deliveryAddress, getDeliverySchedule(deliverySchedule))
          .then(newPaymentOrder => {
            //console.log(`[confirmPaymentOrder] newPaymentOrder: `, newPaymentOrder);

            //onPaymentIntentChange && onPaymentIntentChange(null);
            onPaymentOrderChange && onPaymentOrderChange(newPaymentOrder);

            // clear cart
            clearCart();

            setActiveStep(activeStep + 1);
            setNextButtonDisabled(false);

            firebaseAnalytics.logEvent('purchase', {
              transaction_id: newPaymentOrder.orderId,
              value: newPaymentOrder.order.totalAmount,
              currency: newPaymentOrder.order.currencyId,
              currency: newPaymentOrder.order.taxAmount
            });
          })
        })
        .catch(error => {
          console.log(`[Checkout.confirmPaymentOrder] error: `, error);

          setNextButtonDisabled(false);

          //alert(error.message);
          alert('There was an error confirming your order.\n\nPlease refresh your browser and try again if your order is not confirmed by email within the next 5 minutes.');

          //clearCart();
          handleOnDialogClose();
        })
        break;
      default:
        setActiveStep(activeStep + 1);
        break;
    }
  };

  const handleBack = () => {
    const nextStep = 1; // isFree && activeStep === 2 ? 2 : 1;
    setActiveStep(activeStep - nextStep);
  };

  const onDeliveryAddressChange = (newDeliveryAddress) => {
    dispatch(updateDeliveryAddress(newDeliveryAddress));

    if (newDeliveryAddress.isBillingAddress){
      dispatch(updateBillingAddress(newDeliveryAddress));
    }
    else if (deliveryAddress.isBillingAddress){
      dispatch(updateBillingAddress(DEFAULT_BILLING_ADDRESS));
    }
  }

  const onBillingAddressChange = (newBillingAddress) => {
    dispatch(updateBillingAddress(newBillingAddress));
  }

  const onPaymentMethodChange = (newPaymentMethod) => {
    dispatch(updatePaymentMethod(newPaymentMethod));
  }

  const onPaymentIntentChange = (newPaymentIntent) => {
    dispatch(updatePaymentIntent(newPaymentIntent));
  }

  const onOrderDetailsChange = (newOrderDetails) => {
    //console.log(`[newOrderDetails] `, newOrderDetails)
    dispatch(updateOrderDetails(newOrderDetails));
  }

  const onPaymentOrderChange = (newPaymentOrder) => {
    dispatch(updatePaymentOrder(newPaymentOrder));
  }

  // https://stripe.com/docs/js/payment_methods/create_payment_method
  const getPaymentMethod = async (event) => {
    event && event.preventDefault();

    if (isFree) return Promise.resolve(null);

    // https://stripe.com/docs/js/payment_methods/create_payment_method
    // https://stripe.com/docs/api/payment_methods/object
    // https://stripe.com/docs/api/errors
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: billingAddress.name,
        email: billingAddress.email,
        phone: billingAddress.phone,
        address: {
          city: billingAddress.city,
          country: billingAddress.country,
          line1: billingAddress.street,
          postal_code: billingAddress.postalCode,
          state: billingAddress.state
        }
      },
      metadata: {
        uid: user.uid,
        name: user.displayName,
        photo: user.photoURL,
        email: user.email,
        phone: deliveryAddress.phone
      }
    });

    //console.log(`[getPaymentMethod] error: `, error);
    //console.log(`[getPaymentMethod] paymentMethod: `, paymentMethod);

    if (error) return Promise.reject(error);

    return Promise.resolve(paymentMethod);
  };

  // https://stripe.com/docs/js/payment_intents/confirm_card_payment
  // https://stripe.com/docs/api/errors
  const confirmCardPayment = async (event) => {
    event && event.preventDefault();

    const payment_method = paymentMethod.id;
    const receipt_email = (billingAddress.email || user.email || '').trim();
    //const save_payment_method = false;
    //const setup_future_usage = false;
    const shipping = {
      name: deliveryAddress.name,
      phone: deliveryAddress.phone,
      address: {
        line1: deliveryAddress.street,
        city: deliveryAddress.city,
        country: deliveryAddress.country,
        postal_code: deliveryAddress.postalCode,
        state: deliveryAddress.state
      }
    };

    const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method,
//      shipping,
      receipt_email,
//      setup_future_usage: 'off_session'
    });

    //console.log(`[confirmCardPayment] error: `, result.error);
    //console.log(`[confirmCardPayment] paymentIntent: `, result.paymentIntent);

    if (result.error) return Promise.reject(result.error);

    return Promise.resolve(result.paymentIntent);
  };

  //console.log(`[Checkout] activeStep: ${activeStep} | steps.length: ${steps.length}`);

  return (
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleOnDialogClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
      {/* https://stackoverflow.com/a/51404769 */}
      <DialogTitle disableTypography id="scroll-dialog-title" className={classes.dialogTitle}>
        <h2>{ orderDetails && orderDetails.donation ? 'Donate' : 'Checkout' }</h2>
        <IconButton size="small" onClick={handleOnDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
              <Typography variant="subtitle1">
                Your order number is { paymentOrder.orderId }. We have emailed your order confirmation, and will
                send you an update when your order is delivered.
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <div className={classes.buttons}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} className={classes.button}>
                    Back
                  </Button>
                )}
                <ActivityButton
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={nextButtonDisabled}
                  loading={nextButtonDisabled}
                >
                  {activeStep === steps.length - 1 ? (orderDetails && orderDetails.donation ? 'Donate' : 'Place order') : 'Next'}
                </ActivityButton>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
        <Copyright />
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = ({ auth, checkout }) => ({
  user: auth.user,
  orderDetails: checkout.orderDetails,
  deliveryAddress: checkout.deliveryAddress,
  deliverySchedule: checkout.deliverySchedule,
  billingAddress: checkout.billingAddress,
  paymentMethod: checkout.paymentMethod,
  paymentIntent: checkout.paymentIntent,
  paymentOrder: checkout.paymentOrder,
});

export default connect(
  mapStateToProps,
  //{ loadCart, updateCart, removeProduct, changeProductQuantity }  // for dispatch
)(Checkout);
