import React from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const validation = {
  default: (text) => (!(text && text.trim()) && 'Required') || '',
  email: (text) => (!(text && text.trim()) && 'Invalid Email') || ''
}

const cleanValue = (name, value) => {
  if (name == 'email'){
    return (value && value.trim()) || '';
  }

  return value;
}

const AddressForm = ({ user, donation, deliveryAddress, onChange }) => {

  const handleTextChange = (e) => {
    const name = e.target.name;
    const value = cleanValue(name, e.target.value);

    const newDeliveryAddress = {
      ...deliveryAddress,
      [name]: value
    };

    if (donation) newDeliveryAddress.isBillingAddress = false;

    onChange && onChange(newDeliveryAddress)
  };

  const handleCheckedChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    const newDeliveryAddress = {
      ...deliveryAddress,
      [name]: checked
    };

    if (donation) newDeliveryAddress.isBillingAddress = false;

    onChange && onChange(newDeliveryAddress)
  };

  const addressTitle = donation ? "DONATION RECIPIENT ADDRESS" : "DELIVERY ADDRESS";

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        { addressTitle }
      </Typography>
      <Grid container
            spacing={2}
            //wrap="wrap"
            >
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="name"
            name="name"
            label="Name"
            fullWidth
            autoComplete="name"
            value={deliveryAddress.name}
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="street"
            name="street"
            label="Street"
            fullWidth
            autoComplete="delivery street"
            value={deliveryAddress.street}
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <InputLabel id="city">City</InputLabel>
          <Select
            required
            id="city"
            name="city"
            labelId="city"
            label="City"
            fullWidth
            value={deliveryAddress.city}
            onChange={handleTextChange}
          >
            <MenuItem value={'Campbell'}>Campbell</MenuItem>
            <MenuItem value={'Cupertino'}>Cupertino</MenuItem>
            <MenuItem value={'Gilroy'}>Gilroy</MenuItem>
            <MenuItem value={'Los Altos'}>Los Altos</MenuItem>
            <MenuItem value={'Los Gatos'}>Los Gatos</MenuItem>
            <MenuItem value={'Los Gatos Hills'}>Los Gatos Hills</MenuItem>
            <MenuItem value={'Milpitas'}>Milpitas</MenuItem>
            <MenuItem value={'Monte Sereno'}>Monte Sereno</MenuItem>
            <MenuItem value={'Morgan Hill'}>Morgan Hill</MenuItem>
            <MenuItem value={'Mountain View'}>Mountain View</MenuItem>
            <MenuItem value={'Palo Alto'}>Palo Alto</MenuItem>
            <MenuItem value={'San Jose'}>San Jose</MenuItem>
            <MenuItem value={'Santa Clara'}>Santa Clara</MenuItem>
            <MenuItem value={'Saratoga'}>Saratoga</MenuItem>
            <MenuItem value={'Sunnyvale'}>Sunnyvale</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6} sm={6}>
          <InputLabel id="state">State</InputLabel>
          <Select
            required
            id="state"
            name="state"
            labelId="state"
            //label="State"
            fullWidth
            value={deliveryAddress.state}
            onChange={handleTextChange}
          >
            <MenuItem value={'California'}>California</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            required
            id="postalCode"
            name="postalCode"
            label="Zip / Postal code"
            fullWidth
            autoComplete="delivery postal-code"
            value={deliveryAddress.postalCode}
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            disabled
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="delivery country"
            value={deliveryAddress.country}
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            //autoComplete="delivery email"
            //error={!!validation.email(deliveryAddress.email)}
            //helperText={validation.email(deliveryAddress.email)}
            value={deliveryAddress.email}
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            required
            id="phone"
            name="phone"
            label="Phone"
            fullWidth
            autoComplete="delivery phone"
            value={deliveryAddress.phone}
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" id="isBillingAddress" name="isBillingAddress"
                              checked={donation ? false : `${deliveryAddress.isBillingAddress}` === 'true'} />}
            label="Use this as billing address"
            onChange={handleCheckedChange}
            disabled={donation}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddressForm;
