import { query } from './helpers';

//------------------------------------------------------------------------------
// PAYMENT ORDERS
//------------------------------------------------------------------------------

// create payment intent
export const createPaymentIntent = (paymentMethod, orderDetails, deliveryAddress, billingAddress) => {
  const paymentIntent = {
    paymentMethod, orderDetails, deliveryAddress, billingAddress
  }

  //console.log(`[createPaymentIntent] paymentIntent: `, paymentIntent);
  return query('post', '/orders/intent', paymentIntent);
}

// confirm payment order
export const confirmPaymentOrder = (paymentIntent, paymentMethod, orderDetails, billingAddress, deliveryAddress, deliverySchedule) => {
  return query('post', '/orders/confirm', { paymentIntent, paymentMethod, orderDetails, billingAddress, deliveryAddress, deliverySchedule });
}
