import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

//import moment from 'moment';
import moment from 'moment-timezone';
//https://stackoverflow.com/questions/18448347/how-to-create-time-in-a-specific-time-zone-with-moment-js
moment.tz.setDefault("America/Los_Angeles");

const isoWeekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
}));

const ManageDiscountsTable = props => {
  const { className, discounts,
          handleUpdateDiscount, handleEnableDiscount, handleDisableDiscount, handleDeleteDiscount,
          user,
          ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const isStarted = (date) => {
    const now = moment();
    const startDate = moment(date, 'YYYY-MM-DD').hours(0).startOf('hour');
    return now.isAfter(startDate);
  }

  const isEnded = (date) => {
    const now = moment();
    const endDate = moment(date, 'YYYY-MM-DD').hours(23).endOf('hour');
    return now.isAfter(endDate);
  }

  const getStatus = (discount) => {
    if (discount.disabled) return "DISABLED";

    if (discount.indefinite) return "ACTIVE";

    if ((discount.useCount || 0) >= (discount.limit || 0)) return "FINISHED";

    const started = discount.startDate && isStarted(discount.startDate);
    const ended = discount.endDate && isEnded(discount.endDate);

    if (ended) return "EXPIRED";

    if (started) return "ACTIVE";

    if (discount.startDate && !started) return "FUTURE";

    return "N/A"
  }

  const renderLimits = (discount) => {

    return (
      <div>
        <Grid container spacing={1}>
           <Grid item xs={7} sm={7}>
             <Typography variant="body2" gutterBottom>
               TOTAL
             </Typography>
           </Grid>
           <Grid item xs={5} sm={5}>
             <Typography variant="body2" color="textSecondary">
               { discount.unlimited ?  "UNLIMITED" : `${discount.limit || 0}` }
             </Typography>
           </Grid>
        </Grid>
        <hr/>
        <Grid container spacing={1}>
           <Grid item xs={7} sm={7}>
             <Typography variant="body2" gutterBottom>
               AMOUNT
             </Typography>
           </Grid>
           <Grid item xs={5} sm={5}>
             <Typography variant="body2" color="textSecondary">
               { discount.amountUnlimited ?  "UNLIMITED" : `$${discount.amountLimit || 0}` }
             </Typography>
           </Grid>
         </Grid>

        <Grid container spacing={1}>
           <Grid item xs={7} sm={7}>
             <Typography variant="body2" gutterBottom>
               FOOD
             </Typography>
           </Grid>
           <Grid item xs={5} sm={5}>
             <Typography variant="body2" color="textSecondary">
               { discount.foodUnlimited ?  "UNLIMITED" : `${discount.foodLimit || 0}` }
             </Typography>
           </Grid>
         </Grid>

        <Grid container spacing={1}>
           <Grid item xs={7} sm={7}>
             <Typography variant="body2" gutterBottom>
               DESSERT
             </Typography>
           </Grid>
           <Grid item xs={5} sm={5}>
             <Typography variant="body2" color="textSecondary">
               { discount.otherUnlimited ?  "UNLIMITED" : `${discount.otherLimit || 0}` }
             </Typography>
           </Grid>
         </Grid>

        <Grid container spacing={1}>
           <Grid item xs={7} sm={7}>
             <Typography variant="body2" gutterBottom>
               DRINK
             </Typography>
           </Grid>
           <Grid item xs={5} sm={5}>
             <Typography variant="body2" color="textSecondary">
               { discount.drinkUnlimited ?  "UNLIMITED" : `${discount.drinkLimit || 0}` }
             </Typography>
           </Grid>
         </Grid>
      </div>
    );
  }

  const renderText = (text) => {
    return (
      <Typography variant="body2" color="textSecondary">
        { text }
      </Typography>
    );
  }

  const renderDateRange = (discount) => {

    if (discount.indefinite) return (
      <Typography variant="body2" color="textSecondary">INDEFINITE</Typography>
    );

    if (discount.startDate && !discount.endDate) return (
      <Typography variant="body2" color="textSecondary">{`After ${discount.startDate}`}</Typography>
    );

    if (!discount.startDate && discount.endDate) return (
      <Typography variant="body2" color="textSecondary">{`Until ${discount.endDate}`}</Typography>
    );

    if (discount.startDate && discount.endDate) return (
      <Typography variant="body2" color="textSecondary"><span>{discount.startDate}<br/>to<br/>{discount.endDate}</span></Typography>
    );

    return (
      <Typography variant="body2" color="textSecondary">N/A</Typography>
    );
  }

  const getUseCount = (discount) => {
    return `${discount.useCount || 0}`;
  }

  const getDeliveryDays = (discount) => {
    const days = isoWeekdays.filter((isoWeekday, index) => {
      return !!discount.deliveryDays && !!discount.deliveryDays[`${index + 1}`];
    });

    return <span>
      { days.map((day, i) => <Typography key={i} variant="body2" color="textSecondary"><span>{day}<br/></span></Typography>) }
      </span>;
  }

  const getDeliveryTimes = (discount) => {
    const deliveryTimes = discount.deliveryTimes;

    return <span>
      { deliveryTimes && deliveryTimes.map((deliveryTime, i) => <Typography key={i} variant="body2" color="textSecondary"><span>{moment(deliveryTime, `HH:mm`).format(`h:mm a`)}<br/></span></Typography>) }
      </span>;
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Discount Code</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Sponsor</TableCell>
                  <TableCell style={{ width: 150 }}>Description</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell>Limits</TableCell>
                  <TableCell>Used</TableCell>
                  <TableCell>Date Range</TableCell>
                  <TableCell>Delivery Days</TableCell>
                  <TableCell>Delivery Times</TableCell>
                  <TableCell>Cutoff</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  discounts.map(discount => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={discount.id}
                  >
                    <TableCell>{ renderText(getStatus(discount)) }</TableCell>
                    <TableCell>{ renderText(discount.code) }</TableCell>
                    <TableCell>{ renderText(discount.isEvent ?  "EVENT" : `STANDARD`) }</TableCell>
                    <TableCell>{ renderText(discount.sponsor) }</TableCell>
                    <TableCell>{ renderText(discount.label) }</TableCell>
                    <TableCell>{ renderText(`${discount.discountPercent}%`) } </TableCell>
                    <TableCell>{ renderLimits(discount) }</TableCell>
                    <TableCell>{ renderText(getUseCount(discount)) }</TableCell>
                    <TableCell>{ renderDateRange(discount) }</TableCell>
                    <TableCell>{ getDeliveryDays(discount) }</TableCell>
                    <TableCell>{ getDeliveryTimes(discount) }</TableCell>
                    <TableCell>{ renderText(discount.cutoffDays) }</TableCell>
                    <TableCell>
                      <IconButton size="small"
                                  variant="contained"
                                  style={{ backgroundColor: '#5bc0de', color: 'white', marginRight: 5 }}
                                  onClick={() => handleUpdateDiscount(discount)}>
                        <EditIcon />
                      </IconButton>

                      <IconButton size="small"
                                  variant="contained"
                                  style={{ backgroundColor: '#f0ad4e', color: 'white', marginRight: 5 }}
                                  onClick={() => discount.disabled ? handleEnableDiscount(discount) : handleDisableDiscount(discount)}>
                        { discount.disabled ? <PlayArrowIcon /> : <BlockIcon /> }

                      </IconButton>

                      { user && user.superAdmin &&
                        <IconButton size="small"
                                  variant="contained"
                                  style={{ backgroundColor: '#d9534f', color: 'white' }}
                                  onClick={() => handleDeleteDiscount(discount)}>
                            <DeleteForeverIcon />
                        </IconButton>
                      }

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

ManageDiscountsTable.propTypes = {
  className: PropTypes.string,
  discounts: PropTypes.array.isRequired
};

export default ManageDiscountsTable;
