import React, { useState, createRef } from 'react';

import {loadStripe} from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';

import { stripeConfig } from '../../config';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar, Footer } from './components';
import DrawerCart from '../../components/DrawerCart';
import Checkout from '../../components/Checkout';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  }
}));

const stripePromise = loadStripe(stripeConfig.live ? stripeConfig.livePublishableKey : stripeConfig.testPublishableKey);

const Main = ({ children, user }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);
  const [openDrawerCart, setOpenDrawerCart] = useState(false);
  const [openCheckout, setOpenCheckout] = React.useState(false);

  // Sidebar
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  // DrawerCart
  const handleDrawerCartOpen = () => {
    setOpenDrawerCart(true);
  };

  const handleDrawerCartClose = () => {
    setOpenDrawerCart(false);
  };

  const handleDrawerCartToggle = () => {
    setOpenDrawerCart(!openDrawerCart);
  };

  // Checkout
  const handleCheckoutOpen = () => {
    //console.log(`[handleCheckoutOpen]`);
    handleDrawerCartClose();
    setOpenCheckout(true);
  };

  const handleCheckoutClose = () => {
    setOpenCheckout(false);
  };

  const handleCheckoutToggle = () => {
    setOpenCheckout(!openCheckout);
  };

  const onPayment = (error, paymentMethod) => {
    console.log(`[StripeCheckout] error: `, error);
    console.log(`[StripeCheckout] paymentMethod: `, paymentMethod);
    handleClose();
  }

  const handleClose = () => {
    setOpenCheckout(false);
  };

  /*
  const handleToggle = () => {
    setOpenCheckout(!open);
  };
  */

  const handleOpenCheckout = () => {
    setOpenCheckout(true);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;
  const shouldOpenDrawerCart = openDrawerCart;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Elements stripe={stripePromise}>
        <Topbar onSidebarOpen={handleSidebarOpen} onDrawerCartOpen={handleDrawerCartOpen} onDrawerCartToggle={handleDrawerCartToggle}/>
        <Sidebar
          user={user}
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          variant={isDesktop ? 'persistent' : 'temporary'}
        />
        <DrawerCart
          user={user}
          open={shouldOpenDrawerCart}
          onOpen={handleDrawerCartOpen}
          onClose={handleDrawerCartClose}
          onToggle={handleDrawerCartToggle}
          onOpenCheckout={handleCheckoutOpen}
          variant={isDesktop ? 'persistent' : 'persistent'}
        />
        <Checkout
          open={openCheckout}
          onClose={handleClose}
          onPayment={onPayment}/>
        <main className={classes.content}>
          {children}
          <Footer />
        </main>
      </Elements>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object
};

export default Main;
