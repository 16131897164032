import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { DatePicker } from "@material-ui/pickers";

import { getDateRange } from 'components/DateFilter/DateRangeOptions';
import { dateLabel } from 'redux/util';

//import moment from 'moment';
import moment from 'moment-timezone';
//https://stackoverflow.com/questions/18448347/how-to-create-time-in-a-specific-time-zone-with-moment-js
moment.tz.setDefault("America/Los_Angeles");

export default function DateMenu({ options, defaultDateRange, onDateRangeChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dateRange, setDateRange] = useState(defaultDateRange || {});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (date) => {
    const range = getDateRange(date, date, dateLabel(date, 'CUSTOM'), ['year', 'month', 'day']);
    setDateRange(range);
    onDateRangeChange && onDateRangeChange(range);
    handleClose();
  }

  const handleDateRangeChange = (range) => {
    setDateRange(range);
    onDateRangeChange && onDateRangeChange(range);
    handleClose();
  }

  const getDateLabel = () => {
    const startDate =  dateRange.startDate;
    const endDate =  dateRange.endDate;

    if (startDate.isSame(endDate, 'day')){
      return `${dateRange.label}: ${startDate.format('ddd MMM D YYYY')}`;
    }

    return `${dateRange.label}: ${startDate.format('ddd MMM D YYYY')} - ${endDate.format('ddd MMM D YYYY')}`;
  }

  return (
    <div>
      <Button
        color="primary"
        size="small"
        variant="outlined"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        { getDateLabel() }
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
//        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          <MenuItem>
            <DatePicker
              //className={classes.deliveryDate}
              format="YYYY-MM-DD"
              //label="Start Date"
              //value={moment(discount.startDate, 'YYYY-MM-DD').toDate()}
              labelFunc={() => 'CUSTOM'}
              onChange={handleDateChange}
              showTodayButton
              autoOk
              margin="none"
              fullWidth
              size="small"
            />
          </MenuItem>
        }
        {
          options.map((dateRange, i) => {
            if (dateRange.divider){
              return <div key={i} >
                <MenuItem onClick={() => handleDateRangeChange(dateRange)}>{dateRange.label}</MenuItem>
                <Divider/>
                <Divider/>
                <Divider/>
              </div>
            }
            return <MenuItem key={i} onClick={() => handleDateRangeChange(dateRange)}>{dateRange.label}</MenuItem>
          })
        }
      </Menu>
    </div>
  );
}
