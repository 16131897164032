import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';

import Routes from './Routes';
import { Provider } from "react-redux";
import store from './redux'

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

//import moment from 'moment';
import moment from 'moment-timezone';
//https://stackoverflow.com/questions/18448347/how-to-create-time-in-a-specific-time-zone-with-moment-js
moment.tz.setDefault("America/Los_Angeles");

const browserHistory = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale="en">
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Router history={browserHistory} basename={'/app'}>
              <Routes />
            </Router>
          </ThemeProvider>
        </Provider>
      </MuiPickersUtilsProvider>
    );
  }
}
