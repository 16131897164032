import React from 'react';
import { enableDiscounts } from 'config';

//import moment from 'moment';
import moment from 'moment-timezone';
//https://stackoverflow.com/questions/18448347/how-to-create-time-in-a-specific-time-zone-with-moment-js
moment.tz.setDefault("America/Los_Angeles");

const holidays = [
  '2021-07-14',
  '2021-07-15',
  '2021-07-16',
  '2021-07-17',
  '2021-07-18',
  '2021-07-19',
  '2021-07-20'
];

export const formatPrice = (x, currency) => {
  switch (currency) {
    case 'BRL':
      return x.toFixed(2).replace('.', ',');
    default:
      return x.toFixed(2);
  }
};

export const bulkDiscountAmount = 5;
export const taxRate = 0.0925;;

export const getOrderDetails = (items, tipAmount, bill, discountOffer) => {
  const foodList = [];
  const drinkList = [];
  const otherList = [];

  items.map((item) => {
    if (item.type === 'food') foodList.push(item);
    else if (item.type === 'drink') drinkList.push(item);
    else otherList.push(item);
    return item;
  });

  //const hasBulkDiscount = enableDiscounts && validateBulkDiscount(foodList, drinkList, otherList);
  //console.log(`discountOffer: `, discountOffer);

  const hasDiscount = discountOffer && (typeof discountOffer.discountAmount === 'number');
  const discountCode = (discountOffer && discountOffer.discountCode) || '';
  const discountSponsor = (discountOffer && discountOffer.discountSponsor) || 'EatSimplified';
  const discountAmount = (hasDiscount && discountOffer.discountAmount) || 0;
  //const discount = calculateTotalDiscount(foodList, drinkList, otherList, hasBulkDiscount);

  const subTotal = calculateSubTotalPrice(foodList, drinkList, otherList);
  const discountedSubTotalAmount = subTotal - discountAmount;

  //console.log(`[getOrderDetails] subTotal: ${subTotal}`);
  //console.log(`[getOrderDetails] hasDiscount: ${hasDiscount}`);
  //console.log(`[getOrderDetails] discountAmount: ${discountAmount}`);
  //console.log(`[getOrderDetails] discountedSubTotalAmount: ${discountedSubTotalAmount}`)

  const tax = calculateTax(discountedSubTotalAmount);
  const totalPrice = discountedSubTotalAmount + tax + tipAmount;
  const deliveryFee = 0;

  const isFree = Number(totalPrice.toFixed(2)) === 0;

  const foodQuantity = calculateTotal(foodList);
  const drinkQuantity = calculateTotal(drinkList);
  const otherCount = calculateTotal(otherList);
  const quantity = foodQuantity + drinkQuantity + otherCount;

  //if (quantity !== bill.productQuantity) console.error(`quantity error: ${quantity} !== ${bill.productQuantity}`);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: bill.currencyId,  //'USD'
  });

  const orderDetails = {
    subTotalAmount: Number(subTotal.toFixed(2)),
    subTotal: currencyFormatter.format(subTotal),

    isFree,
    hasDiscount,
    discountCode,
    discountSponsor,
    discountAmount: Number(discountAmount.toFixed(2)),
    discount: currencyFormatter.format(discountAmount), // totalDiscount
    discountedSubTotalAmount: Number(discountedSubTotalAmount.toFixed(2)),
    discountedSubTotal: currencyFormatter.format(discountedSubTotalAmount),

    tipAmount: Number(tipAmount.toFixed(2)),
    tip: currencyFormatter.format(tipAmount),

    totalAmount: Number(totalPrice.toFixed(2)),
    totalPrice: currencyFormatter.format(totalPrice),

    taxAmount: Number(tax.toFixed(2)),
    tax: currencyFormatter.format(tax),

    deliveryFeeAmount: deliveryFee,
    deliveryFee: currencyFormatter.format(deliveryFee),

    currencyId: bill.currencyId,
    currencyFormat: bill.currencyFormat,

    quantity,
    foodQuantity,
    foodList,
    drinkQuantity,
    drinkList,
    otherCount,
    otherList
  }

  const foodDescription = orderDetails.foodQuantity > 0 ? (orderDetails.foodQuantity > 1 ? `${orderDetails.foodQuantity} entrées` : `1 entrée`) : null;
  const drinkDescription = orderDetails.drinkQuantity > 0 ? (orderDetails.drinkQuantity > 1 ? `${orderDetails.drinkQuantity} drinks` : `1 drink`) : null;
  const otherDescription = orderDetails.otherCount > 0 ? (orderDetails.otherCount > 1 ? `${orderDetails.otherCount} desserts` : `1 dessert`) : null;

  const mealDescription = (foodDescription && drinkDescription && otherDescription && `${foodDescription}, ${drinkDescription}, ${otherDescription}`)
                          || (foodDescription && drinkDescription && `${foodDescription}, ${drinkDescription}`)
                          || (foodDescription && otherDescription && `${foodDescription}, ${otherDescription}`)
                          || (drinkDescription && otherDescription && `${drinkDescription}, ${otherDescription}`)
                          || foodDescription
                          || drinkDescription;

  orderDetails.description = hasDiscount ? `${mealDescription}, with ${orderDetails.discount} discount, ${orderDetails.tip} tip, for a total of ${orderDetails.totalPrice}.`
                                : `${mealDescription}, ${orderDetails.tip} tip, for a total of ${orderDetails.totalPrice}.`;

  return orderDetails;
}

export const validateBulkDiscount = (foodList, drinkList, otherList) => {
  const foodQuantity =  foodList.reduce((total, food) => total + food.quantity, 0);
  return foodQuantity >= bulkDiscountAmount;
}

export const calculateTotal = (list) => {
  const count =  list.reduce((total, item) => total + item.quantity, 0);
  return count;
}

export const calculateTax = (totalPrice) => {
  return totalPrice * taxRate;
}

export const calculateSubTotalPrice = (foodList, drinkList, otherList, hasBulkDiscount) => {
  const foodPrice =  foodList.reduce((total, food) => total + (food.quantity * (hasBulkDiscount ? food.bulkPrice : food.price)), 0);
  const drinkPrice =  drinkList.reduce((total, drink) => total + (drink.quantity * (hasBulkDiscount ? drink.bulkPrice : drink.price)), 0);
  const otherPrice =  otherList.reduce((total, other) => total + (other.quantity * (hasBulkDiscount ? other.bulkPrice : other.price)), 0);

  return foodPrice + drinkPrice + otherPrice;
}

export const calculateTotalDiscount = (foodList, drinkList, otherList, hasBulkDiscount) => {
  const foodDiscount =  foodList.reduce((total, food) => total + (food.quantity * (hasBulkDiscount ? food.price - food.bulkPrice : 0)), 0);
  const drinkDiscount =  drinkList.reduce((total, drink) => total + (drink.quantity * (hasBulkDiscount ? drink.price - drink.bulkPrice : 0)), 0);
  const otherDiscount =  otherList.reduce((total, other) => total + (other.quantity * (hasBulkDiscount ? other.price - other.bulkPrice : 0)), 0);
  return foodDiscount + drinkDiscount + otherDiscount;
}

export const isWeekend = (date) => {
  const dayOfWeek = date.isoWeekday();
  return (dayOfWeek === 6 || dayOfWeek === 7);
}

export const isHoliday = (date) => {
  return !!holidays.find(holiday => date.isSame(holiday, 'day'));
}

export const isWeekendOrHoliday = (date) => {
    return isWeekend(date) || isHoliday(date);
}

export const isTodayAfterNoon = (date) => {
  const noon = moment().hours(12).startOf('hour');
  return date.isSame(noon, 'day') && date.isAfter(noon);
}

export const dateLabel = (date, defaultLabel, defaultFormat)  => {
  const now = moment();
  const tomorrow  = moment().add(1,'days');
  const yesterday  = moment().subtract(1,'days');

  if (date.isSame(now, 'day')){
    return 'Today';
  }
  else if (date.isSame(tomorrow, 'day')){
    return 'Tomorrow';
  }
  else if (date.isSame(yesterday, 'day')){
    return 'Yesterday';
  }

  return defaultLabel || date.format(defaultFormat || 'ddd MMM D YYYY');
}

export const nextAvailableDate = (from) => {
  let nextAvailable = from || moment();

  while(isWeekendOrHoliday(nextAvailable)){
    nextAvailable = nextAvailable.add(1, 'days');
  }

  //console.log(`nextAvailable: `, nextAvailable);
  return nextAvailable;
}

export const shouldDisableDate = (calendarDate: DateIOType, deliveryDays, discountOffer)  => {
  const date = moment(calendarDate);
  const dayOfWeek = date.isoWeekday();
  const isEvent = discountOffer && discountOffer.isEvent;

  if (isHoliday(date)) return true;

  if (deliveryDays){
    if (isEvent){
      const validDeliveryDates = getValidDeliveryDates(deliveryDays, discountOffer);

      const isValidDeliveryDate = validDeliveryDates && validDeliveryDates.some((d) => date.isSame(d, 'day'));

      return !isValidDeliveryDate;
    }

    return !deliveryDays[`${dayOfWeek}`];
  }

  return dayOfWeek === 6 || dayOfWeek === 7 || isTodayAfterNoon(date);
}

export const getDeliveryDayOfWeek = (day, cutoffDays=0) => {
  const now =  moment();
  const noon = moment().hours(12).startOf('hour');
  const isBeforeNoon = now.isBefore(noon);

  const today = now.isoWeekday();
  const dayINeed = parseInt(day);

  const cutoffDay = today + cutoffDays;

  //console.log(`getDeliveryDayOfWeek: isBeforeNoon: ${isBeforeNoon} | dayINeed: ${dayINeed} | cutoffDay: ${cutoffDay} | cutoffDays: `, cutoffDays);

  if (cutoffDay === dayINeed){
    return isBeforeNoon ? moment().isoWeekday(dayINeed)
                        : moment().add(1, 'weeks').isoWeekday(dayINeed);
  }
  else if (cutoffDay < dayINeed){
    return moment().isoWeekday(dayINeed);
  }

  return moment().add(1, 'weeks').isoWeekday(dayINeed);
}

export const getValidDeliveryDates = (deliveryDays, discountOffer) => {
  const isEvent = discountOffer && !!discountOffer.isEvent;
  const cutoffDays = discountOffer && discountOffer.cutoffDays >= 0 ? (discountOffer.cutoffDays || 0) : 1;

  const validDeliveryDates = Object.keys(deliveryDays)
                           .filter((deliveryDay, i) => !!deliveryDays[deliveryDay])
                           .map((deliveryDay, i) => {
                             return getDeliveryDayOfWeek(deliveryDay, cutoffDays);
                           })
                           .sort((a, b) => a.unix() - b.unix());

  //console.log(`[util.validDeliveryDates] isEvent: ${isEvent} | cutoffDays: ${cutoffDays} | validDeliveryDates: `, validDeliveryDates);
  return validDeliveryDates;
}

export const nextDeliveryDate = (deliveryDays, discountOffer) => {
  const today =  moment();
  const tomorrow = moment().add(1,'days');

  const isEvent = discountOffer && !!discountOffer.isEvent;
  const cutoffDays = discountOffer && discountOffer.cutoffDays >= 0 ? (discountOffer.cutoffDays || 0) : 1;

  if (deliveryDays){
    //console.log(`[util.nextDeliveryDate]: deliveryDays:`, deliveryDays);
    const dayOfWeek = today.isoWeekday();

    const deliveryDaysOfWeek = getValidDeliveryDates(deliveryDays, discountOffer);

    //console.log(`[util.nextDeliveryDate] isEvent: ${isEvent} | cutoffDays: ${cutoffDays} | dayOfWeek: ${dayOfWeek} `, deliveryDaysOfWeek);

    if (deliveryDaysOfWeek.length > 0) return deliveryDaysOfWeek[0];

    return today.add(1, 'weeks').isoWeekday(1);
  }

  // no weekends, return next weekday, Monday
  if (isWeekend(today)) return nextAvailableDate(today.add(1, 'weeks').isoWeekday(1));

  if (isHoliday(today)) return nextAvailableDate(tomorrow);

  // before noon, can do same day delivery
  if (!isTodayAfterNoon(today)) return today;

  if (isWeekend(tomorrow)) return nextAvailableDate(today.add(1, 'weeks').isoWeekday(1));

  // else tomorrow
  return nextAvailableDate(tomorrow);
}

export const getDefaultDeliveryTime = (discountOffer) => {
  const deliveryTimes = discountOffer && discountOffer.deliveryTimes;
  return (deliveryTimes && deliveryTimes.length > 0) ? deliveryTimes[0] : '17:30';
}

export const getDeliverySchedule = (deliverySchedule) => {
  if (!deliverySchedule) return {};

  const deliveryDate = moment(deliverySchedule.date);

  const date = deliveryDate.format('YYYY-MM-DD');
  const standardDate = deliveryDate.format('ddd, MMM DD, YYYY');

  const time = deliverySchedule.time;
  const standardTime = moment(deliverySchedule.time, 'hh:mm').format('h:mm a');

  const dateTime = `${standardDate} by ${standardTime}`;

  const timestamp = deliverySchedule.date.getTime();

  return { date, time, standardDate, standardTime, dateTime, timestamp };
}
