import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { UsersTable } from './components';

import { getUsers, updateUserRole, repairUser } from 'api/admin';
import { getQueryParams } from 'api/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const UserList = () => {
  const classes = useStyles();

  const [refreshing, setRefreshing] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [listQuery, setListQuery] = useState({ filterKey: 'name', filterValue: null, page: 0 });

  const [listData, setListData] = useState({
    data: [],
    length: 0,
    sections: [],
    canFetchMore: true,
  });

  useEffect(() => {
   fetchData();
  }, [listQuery]);

  //----------------------------------------------------------------------------
  // API
  //----------------------------------------------------------------------------

  const fetchData = () => {
    // https://stackoverflow.com/questions/37230555/get-with-query-string-with-fetch-in-react-native
    setRefreshing(true);

    const queryParams = getQueryParams(listQuery.filterValue,
                                       listQuery.filterKey,
                                       listQuery.page, rowsPerPage);

    //getUsers()
    getUsers(queryParams)
    .then(users => {

      const data = (listQuery.page > 0 ? listData.data.concat(users) : users);
      const length = data.length;
      const canFetchMore = !(length < rowsPerPage || length % rowsPerPage != 0);

      setListData({ data, length, canFetchMore });

      setRefreshing(false);
    })
    .catch(error => {
      console.log(`[UsersList.fetchData] ERROR: `, error);
      setRefreshing(false);
    })
  };

  const onUpdateUserRole = (user, role) => {
    setRefreshing(true);
    updateUserRole && updateUserRole(user.uid, role)
    .then(updatedUser => {
      // replace
      const index = listData.data.indexOf(user);

      if (index !== -1) {
        listData.data[index] = updatedUser;
      }

      setListData({ ...listData });
      setRefreshing(false);
    })
    .catch(error => {
      console.log(`[UsersList.onUpdateUserRole] ERROR: `, error);
      setRefreshing(false);
    })
  }

  const onRepairUser = (user, diagnostics) => {
    setRefreshing(true);
    repairUser && repairUser(user.uid, diagnostics)
    .then(updatedUser => {
      // replace
      const index = listData.data.indexOf(user);

      if (index !== -1) {
        listData.data[index] = updatedUser;
      }

      setListData({ ...listData });
      setRefreshing(false);
    })
    .catch(error => {
      console.log(`[UsersList.onRepairUser] ERROR: `, error);
      setRefreshing(false);
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Backdrop className={classes.backdrop} open={refreshing} onClick={() => setRefreshing(false)}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <UsersTable users={listData.data} onUpdateUserRole={onUpdateUserRole} onRepairUser={onRepairUser} />
      </div>
    </div>
  );
};

export default UserList;
