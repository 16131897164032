import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';

export default function DropdownButton({ options, selectedOption, onSelected, titleKey, subtitleKey }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [selectedIndex, setSelectedIndex] = React.useState(options && options.indexOf(selectedOption) >= 0 ? options.indexOf(selectedOption) : 0);

  const handleClick = () => {
    //console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);

    options && options.length && onSelected && onSelected(options[index], index);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const getTitle = (option) => {
    const label = option && option[titleKey] ? option[titleKey] : option;

    return (
      <span style={getStyle(option)}>
        { label }
      </span>
    )
  }

  const getSubtitle = (option) => {
    const label = option && option[subtitleKey] ? option[subtitleKey] : null;

    if (!label) return <div/>;

    return (
        <Typography
          component="span"
          variant="overline"
          style={getStyle(option)}
        >
        {  ` : ${label}` }
        </Typography>
    )
  }

  const getStyle = (option) => {
    if (option.color && option.backgroundColor){
      return {
        color: option.color,
        backgroundColor: option.backgroundColor
      }
    }

    return null;
  }

  const defaultStyle = options && options.length && getStyle(options[selectedIndex]) || null;
  const title =  options && options.length && getTitle(options[selectedIndex]) || null;
  const subTitle =  options && options.length && getSubtitle(options[selectedIndex]) || null;

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup variant="contained" style={defaultStyle} ref={anchorRef} aria-label="split button">
          <Button onClick={handleClick} style={defaultStyle}>
            { title }
            { subTitle }
          </Button>
          <Button
            color="secondary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition  >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        style={getStyle(option)}
                        key={index}
                        //disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        { getTitle(option) }
                        { getSubtitle(option) }
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}
