import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

import { RouteWithLayout, ProtectedRouteWithLayout, StaffRouteWithLayout, AdminRouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Account as AccountView,
  Admin as AdminView,
  Dashboard as DashboardView,
  Icons as IconsView,
  Login as LoginView,
  ManageDeliveries as ManageDeliveriesView,
  ManageOrders as ManageOrdersView,
  NotFound as NotFoundView,
  Order as OrderView,
  OrderHistory as OrderHistoryView,
  ProductList as ProductListView,
  Settings as SettingsView,
  SignIn as SignInView,
  SignUp as SignUpView,
  Typography as TypographyView,
  UserList as UserListView,
  UserDiscounts as UserDiscountsView,
  ManageDiscounts as ManageDiscountsView,
  ManageMenu as ManageMenuView,
} from './views';

const Routes = ({ isAuthenticated, isVerifying, user } ) => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/order"
      />
      <ProtectedRouteWithLayout
        component={OrderView}
        exact
        layout={MainLayout}
        path="/order"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={OrderHistoryView}
        exact
        layout={MainLayout}
        path="/history"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <StaffRouteWithLayout
        component={ManageDeliveriesView}
        exact
        layout={MainLayout}
        path="/manage-deliveries"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <StaffRouteWithLayout
        component={ManageOrdersView}
        exact
        layout={MainLayout}
        path="/manage-orders"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <AdminRouteWithLayout
        component={AdminView}
        exact
        layout={MainLayout}
        path="/admin"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <AdminRouteWithLayout
        component={ManageMenuView}
        exact
        layout={MainLayout}
        path="/manage-menu"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <AdminRouteWithLayout
        component={ManageDiscountsView}
        exact
        layout={MainLayout}
        path="/manage-discounts"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <AdminRouteWithLayout
        component={UserDiscountsView}
        exact
        layout={MainLayout}
        path="/user-discounts"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <AdminRouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/user-roles"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <ProtectedRouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/login"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
        user={user}
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(Routes);
