import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Backdrop, CircularProgress, Grid } from '@material-ui/core';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RestaurantIcon from '@material-ui/icons/Restaurant';

import {
  RecentOrders
} from './components';

import MetricPanel from 'components/MetricPanel';

import moment from 'moment';

import { getUserOrders } from 'api/users';
import { getQueryParams } from 'api/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  icon: {
    height: 32,
    width: 32
  }
}));

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const OrderHistory = () => {
  const classes = useStyles();

  const [refreshing, setRefreshing] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [listQuery, setListQuery] = useState({ filterKey: 'date', filterValue: null, page: 0 });

  const [listData, setListData] = useState({
    data: { stats: {}, orders: [] },
    length: 0,
    sections: [],
    canFetchMore: true,
  });

  //----------------------------------------------------------------------------
  // API
  //----------------------------------------------------------------------------

  const fetchData = () => {
    // https://stackoverflow.com/questions/37230555/get-with-query-string-with-fetch-in-react-native
    setRefreshing(true);

    const date = moment();
    const year = date.format(`YYYY`);
    const month = date.format(`MM`);
    const day = date.format(`DD`);

    const queryParams = getQueryParams(listQuery.filterValue,
                                       listQuery.filterKey,
                                       listQuery.page, rowsPerPage);

    getUserOrders(queryParams)
    .then(({ stats, orders}) => {
      //console.log(`stats: `, stats);
      //console.log(`orders: `, orders);

      const data = {
        stats,
        orders: (listQuery.page > 0 ? listData.data.orders.concat(orders) : orders)
      };

      const length = data.orders.length;
      const canFetchMore = !(length < rowsPerPage || length % rowsPerPage !== 0);

      setListData({ data, length, canFetchMore });

      setRefreshing(false);
    })
    .catch(error => {
      console.log(`[UsersList.fetchData] ERROR: `, error);
      setRefreshing(false);
    })
  };

  useEffect(() => {
   fetchData();
  }, [listQuery]);

  const stats = listData.data.stats;
  const orders = listData.data.orders;

  const ordersDay = stats.day ? stats.day.orderQuantity : 0;
  const ordersYTD = stats.year ? stats.year.orderQuantity : 0;

  const tipAmountDay = currencyFormatter.format(stats.day ? ((stats.day.tipAmount || 0)/100) : 0);
  const tipAmountYTD = currencyFormatter.format(stats.year ? ((stats.year.tipAmount || 0)/100) : 0);

  const revenueDay = currencyFormatter.format(stats.day ? ((stats.day.amount || 0)/100) : 0);
  const revenueYTD = currencyFormatter.format(stats.year ? ((stats.year.amount || 0)/100) : 0);

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={refreshing} onClick={() => setRefreshing(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        spacing={4}
      >
        {/*
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <MetricPanel title="ORDERS TODAY"
                 color="warning"
                 icon={<RestaurantIcon className={classes.icon}/>}
                 value={ordersDay}/>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
        <MetricPanel title="SAVINGS TODAY"
               color="info"
               icon={<RestaurantIcon className={classes.icon}/>}
               value={revenueDay}/>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <MetricPanel title="TIPS TODAY"
                 color="primary"
                 icon={<AttachMoneyIcon className={classes.icon}/>}
                 value={tipAmountDay}/>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <MetricPanel title="SAVINGS YTD"
                 color="success"
                 icon={<AttachMoneyIcon className={classes.icon}/>}
                 value={revenueYTD}/>
        </Grid>
        */}
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <RecentOrders title="RECENT ORDERS" orders={orders}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderHistory;
