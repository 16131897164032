import { query, expandQueryParams, getParam } from './helpers';

//------------------------------------------------------------------------------
// User Endpoints
//------------------------------------------------------------------------------

export const getUserProfile = (uid) => {
 return query('get', `/admin/users/${uid}`);
}

export const getUsers = (queryParams) => {
 return query('get', `/admin/users${expandQueryParams(queryParams)}`);
}

export const updateUser = (uid, userData) => {
 return query('put', `/admin/users/${uid}`, userData);
}

export const updateUserRole = (uid, role) => {
 return query('put', `/admin/users/${uid}/role`, { role });
}

export const repairUser = (uid, diagnostics) => {
 return query('put', `/admin/users/${uid}/repair`, { diagnostics });
}

//------------------------------------------------------------------------------
// Discount Endpoints
//------------------------------------------------------------------------------

export const createOrUpdateDiscount = (discountOffer) => {
 return query('post', `/admin/discounts`, discountOffer);
}

export const getDiscountOffers = () => {
 return query('get', `/admin/discounts`);
}

export const getDiscountOfferStats = (startDate, endDate, year, month, week, day) => {
  const args = [];

  startDate && args.push(getParam(`startDate`, startDate));
  endDate && args.push(getParam(`endDate`, endDate));

  year && args.push(getParam(`year`, year));
  month && args.push(getParam(`month`, month));
  week && args.push(getParam(`week`, week));
  day && args.push(getParam(`day`, day));

  const queryParams = args.length > 0 ? '?' + args.join('&') : '';

  return query('get', `/admin/discounts/stats/${queryParams}`);
}

export const grantUserDiscount = (uid, discountCode) => {
 return query('post', `/admin/discounts/grant`, { uid, discountCode });
}

export const enableDiscountOffer = (discountOfferId) => {
 return query('put', `/admin/discounts/enable/${discountOfferId}`);
}

export const disableDiscountOffer = (discountOfferId) => {
 return query('put', `/admin/discounts/disable/${discountOfferId}`);
}

export const deleteDiscountOffer = (discountOfferId) => {
 return query('delete', `/admin/discounts/${discountOfferId}`);
}


//------------------------------------------------------------------------------
// Orders Endpoints
//------------------------------------------------------------------------------

export const getOrders = (startDate, endDate, byOrderDate) => {
  const args = [];

  startDate && args.push(getParam(`startDate`, startDate));
  endDate && args.push(getParam(`endDate`, endDate));

  byOrderDate && args.push(getParam(`byOrderDate`, byOrderDate));

  const queryParams = args.length > 0 ? '?' + args.join('&') : '';

  return query('get', `/admin/orders/stats${queryParams}`);
}

export const getOrderStats = (startDate, endDate, year, month, week, day, byOrderDate) => {
  const args = [];

  startDate && args.push(getParam(`startDate`, startDate));
  endDate && args.push(getParam(`endDate`, endDate));

  byOrderDate && args.push(getParam(`byOrderDate`, byOrderDate));

  year && args.push(getParam(`year`, year));
  month && args.push(getParam(`month`, month));
  week && args.push(getParam(`week`, week));
  day && args.push(getParam(`day`, day));

  const queryParams = args.length > 0 ? '?' + args.join('&') : '';

  return query('get', `/admin/orders/stats${queryParams}`);
}

//------------------------------------------------------------------------------
// Menu Endpoints
//------------------------------------------------------------------------------

export const createOrUpdateMenuItem = (menuItem) => {
 return query('post', `/admin/menu`, menuItem);
}

export const getMenuItems = () => {
 return query('get', `/admin/menu`);
}

export const getMenuStats = (startDate, endDate, year, month, week, day) => {
  const args = [];

  startDate && args.push(getParam(`startDate`, startDate));
  endDate && args.push(getParam(`endDate`, endDate));

  year && args.push(getParam(`year`, year));
  month && args.push(getParam(`month`, month));
  week && args.push(getParam(`week`, week));
  day && args.push(getParam(`day`, day));

  const queryParams = args.length > 0 ? '?' + args.join('&') : '';

  return query('get', `/admin/menu/stats/${queryParams}`);
}

export const enableMenuItem = (menuItemId) => {
 return query('put', `/admin/menu/enable/${menuItemId}`);
}

export const disableMenuItem = (menuItemId) => {
 return query('put', `/admin/menu/disable/${menuItemId}`);
}

export const deleteMenuItem = (menuItemId) => {
 return query('delete', `/admin/menu/${menuItemId}`);
}
