import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  //CardActions,
  CardHeader,
  CardContent,
  //Button,
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormGroup,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from '@material-ui/core';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MaterialTable from 'material-table'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  avatar: {
    //marginRight: theme.spacing(2),
    borderRadius: 10
  },
}));

const statusColors = {
  delivered: 'success',
  pending: 'info',
  refunded: 'danger'
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const LatestOrders = props => {
  const { className, title, orders, ...rest } = props;

  const classes = useStyles();

  const [filters, setFilters] = React.useState({ fullPrice: true, isFree: true, hasDiscount: true });

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const filterOrders = (order) => {
    if (filters.fullPrice && !order.order.isFree && !order.order.hasDiscount) return true;
    if (filters.isFree && !!order.order.isFree) return true;
    if (filters.hasDiscount && !order.order.isFree && !!order.order.hasDiscount) return true;

    return false;
  }

  //orders.map((order) => order.discountCode && console.log(order))

  const renderMenuItems = (list, order) => {
    if (!list || list.length === 0) return <div/>

    return (
      <div>
      {
        list.map(item => {
          return (
            <Grid key={item.sku} container spacing={1}>
               <Grid item xs={11} sm={11}>
                 <Typography variant="body2" gutterBottom>
                   { item.title }
                 </Typography>
               </Grid>
               <Grid item xs={1} sm={1}>
                 <Typography variant="body2" color="textSecondary">
                   { item.quantity }
                 </Typography>
               </Grid>
             </Grid>
         )
        })
      }
      </div>
    );
  }

  const renderInstructions = (instructions) => {
    if (!instructions) return '';

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography noWrap={true} style={{ width: 50 }} variant="body2" color="textSecondary">{instructions}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography variant="body2" color="textSecondary">
            {instructions}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <FormGroup row>
            <FormControlLabel
              control={<Switch checked={filters.fullPrice} onChange={handleFilterChange} name="fullPrice" />}
              label={<span style={{color: '#5cb85c'}}>FULL PRICE</span>}
            />
            <FormControlLabel
              control={<Switch checked={filters.hasDiscount} onChange={handleFilterChange} name="hasDiscount"/>}
              label={<span style={{color: '#428bca'}}>DISCOUNTED</span>}
            />
            <FormControlLabel
              control={<Switch checked={filters.isFree} onChange={handleFilterChange} name="isFree"/>}
              label={<span style={{color: '#d9534f'}}>FREE</span>}
            />
          </FormGroup>
        }
        title={title}
      />
      <Divider />
      <CardContent className={classes.content}>
        <MaterialTable
          options={{
            exportButton: true,
            exportFileName: `EatSimplified - Order Details`,
            filtering: true,
            search: true,
            pageSize: 400,
            pageSizeOptions: [25, 50, 100, 200, 400, 800, 1600],
            rowStyle: {
              borderBottom: "2px solid black"
            }
          }}
          data={orders.filter(filterOrders)}
          title={''}
          columns={[
            { field: 'name', title: 'Customer',
              render: order => <Grid container direction="column" spacing={1}>
                <Grid item xs={12} sm={12}>
                   <Avatar
                     className={classes.avatar}
                     src={order.picture}
                   />
                </Grid>
                <Grid item xs>
                   <Typography variant="body1" gutterBottom>
                     { order.name }
                   </Typography>
                </Grid>
                <Grid item xs>
                   <Typography variant="body2" color="textSecondary">
                     { order.email }
                   </Typography>
                </Grid>
              </Grid>
            },
            { field: 'orderId', title: 'Order #',
              render: order => <Typography variant="body2">{ order.orderId }</Typography>
            },
            { field: 'order.foodQuantity', type: 'numeric', title: 'Entrées',
              filtering: false, searchable: false,
              render: order => {
                return (
                  <React.Fragment>
                    {
                      renderMenuItems(order.order.foodList)
                    }
                    { !!order.order.foodQuantity && <hr/> }
                    { !!order.order.foodQuantity &&
                      <Grid container spacing={1}>
                         <Grid item xs={11} sm={11}>
                           <Typography variant="body2" gutterBottom>
                             TOTAL ENTRÉES
                           </Typography>
                         </Grid>
                         <Grid item xs={1} sm={1}>
                           <Typography variant="body2">
                             { order.order.foodQuantity }
                           </Typography>
                         </Grid>
                       </Grid>
                    }
                  </React.Fragment>
                )
              }
            },
            { field: 'order.otherCount', type: 'numeric', title: 'Dessert',
              filtering: false, searchable: false,
              render: order => {
                return (
                  <React.Fragment>
                    {
                      renderMenuItems(order.order.otherList)
                    }
                    { !!order.order.otherCount && <hr/> }
                    { !!order.order.otherCount &&
                      <Grid container spacing={1}>
                         <Grid item xs={11} sm={11}>
                           <Typography variant="body2" gutterBottom>
                             TOTAL DESSERTS
                           </Typography>
                         </Grid>
                         <Grid item xs={1} sm={1}>
                           <Typography variant="body2">
                             { order.order.otherCount }
                           </Typography>
                         </Grid>
                       </Grid>
                    }
                  </React.Fragment>
                )
              }
            },
            { field: 'order.drinkQuantity', type: 'numeric', title: 'Drinks',
              filtering: false, searchable: false,
              render: order => {
                return (
                  <React.Fragment>
                    {
                      renderMenuItems(order.order.drinkList)
                    }
                    { !!order.order.drinkQuantity && <hr/> }
                    { !!order.order.drinkQuantity &&
                      <Grid container spacing={1}>
                         <Grid item xs={11} sm={11}>
                           <Typography variant="body2" gutterBottom>
                             TOTAL DRINKS
                           </Typography>
                         </Grid>
                         <Grid item xs={1} sm={1}>
                           <Typography variant="body2">
                             { order.order.drinkQuantity }
                           </Typography>
                         </Grid>
                       </Grid>
                    }
                  </React.Fragment>
                )
              }
            },
            { field: 'delivery.fullAddress', title: 'Address' },
            { field: 'schedule.dateTime', title: 'Delivery Time',
              render: order => `${ order.schedule.dateTime }`
            },
            { field: 'order.instructions', title: 'Instructions',
              render: order => renderInstructions(order.order.instructions)
            },
            { field: 'order.discountCode', title: 'Promo Code' },
            { field: 'order.discountAmount', type: 'numeric', title: 'Discount',
              filtering: false, searchable: false,
              render: order => !!order.order.discountAmount && order.order.discount
            },
            { field: 'order.tipAmount', type: 'numeric', title: 'Tip',
              filtering: false, searchable: false,
              render: order => order.order.tip
            },
            { field: 'order.totalAmount', type: 'numeric', title: 'Amount',
              filtering: false, searchable: false,
              render: order => order.order.totalPrice
            },
          ]}
        />
      </CardContent>
      {/*
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
      */}
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;
