import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 40,
    width: 40,
    borderRadius: 5,
  },
  actions: {
    justifyContent: 'flex-end'
  },
  details: {
    justifyContent: 'space-between'
  }
}));


const CartItem = ({ product, changeProductQuantity, removeProduct, divider }) => {
  const classes = useStyles();

  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseOver = () => {
    setIsMouseOver(true);
  };

  const handleMouseOut = () => {
    setIsMouseOver(false);
  };

  const handleOnIncrease = () => {
    product.quantity = product.quantity + 1;
    changeProductQuantity(product);
  }

  const handleOnDecrease = () => {
    product.quantity = product.quantity - 1;
    changeProductQuantity(product);
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: product.currencyId,  //'USD'
  });

  const primary = (
    <Typography variant="overline" color="textSecondary" component="p">
      {`${product.title}`}
    </Typography>
  );

  const secondary = (
    <Typography variant="caption" color="textSecondary" component="p" className={classes.detail}>
      x{ product.quantity }
      <IconButton
        className={classes.actions}
        edge="end"
        size="small"
        onClick={handleOnDecrease}
        //disabled={product.quantity === 1 ? true : false}
      >
        <RemoveIcon />
      </IconButton>
      <IconButton
      className={classes.actions}
        edge="end"
        size="small"
        onClick={handleOnIncrease}
      >
        <AddIcon />
      </IconButton>
      {currencyFormatter.format(product.quantity * product.price)}
    </Typography>
  );

  return (
    <ListItem
      divider={divider}
      key={product.id}
    >
      <ListItemAvatar>
        <img
          alt="Product"
          className={classes.image}
          src={product.image}
        />
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
}

CartItem.propTypes = {
  product: PropTypes.object.isRequired,
  removeProduct: PropTypes.func.isRequired,
  changeProductQuantity: PropTypes.func.isRequired,
};

export default CartItem;
