import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Backdrop, CircularProgress, Grid } from '@material-ui/core';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RestaurantIcon from '@material-ui/icons/Restaurant';

import {
  OrderDetailsTable,
  OrderSummary
} from './components';

import MetricPanel from 'components/MetricPanel';
import { DateFilter, getDateRangeOptions } from 'components/DateFilter';

import { getOrders, getOrderStats } from 'api/admin';
import { getQueryParams } from 'api/helpers';

const rangeOptions = getDateRangeOptions();
const defaultDateRange = rangeOptions[1]; // Today

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  icon: {
    height: 32,
    width: 32
  }
}));

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const ManageOrders = () => {
  const classes = useStyles();

  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [byOrderDate, setByOrderDate] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [listQuery, setListQuery] = useState({ filterKey: 'date', filterValue: null, page: 0 });

  const [listData, setListData] = useState({
    data: { stats: {}, orders: [] },
    length: 0,
    sections: [],
    canFetchMore: true,
  });

  //----------------------------------------------------------------------------
  // API
  //----------------------------------------------------------------------------

  const onDateRangeChange = (range) => {
    setDateRange(range);
  }

  const fetchData = () => {
    // https://stackoverflow.com/questions/37230555/get-with-query-string-with-fetch-in-react-native
    setRefreshing(true);

    const queryParams = getQueryParams(listQuery.filterValue,
                                       listQuery.filterKey,
                                       listQuery.page, rowsPerPage);


    getOrderStats(dateRange.startDate.format('YYYY-MM-DD'), dateRange.endDate.format('YYYY-MM-DD'),
                  dateRange.stats.year, dateRange.stats.month, dateRange.stats.week, dateRange.stats.day,
                  byOrderDate, queryParams)
    .then(({ stats, orders}) => {
      //console.log(`stats: `, stats);
      //console.log(`orders: `, orders);

      const data = {
        stats,
        orders: (listQuery.page > 0 ? listData.data.orders.concat(orders) : orders)
      };

      const length = data.orders.length;
      const canFetchMore = !(length < rowsPerPage || length % rowsPerPage !== 0);

      setListData({ data, length, canFetchMore });

      setRefreshing(false);
    })
    .catch(error => {
      console.log(`[UsersList.fetchData] ERROR: `, error);
      setRefreshing(false);
    })
  };

  useEffect(() => {
   fetchData();
 }, [listQuery, dateRange]);

  const stats = listData.data.stats;
  const orders = listData.data.orders;

  const orderCount = stats.data ? stats.data.orderQuantity : 0;
  const tipAmount = currencyFormatter.format(stats.data ? ((stats.data.tipAmount || 0)/100) : 0);
  const tax = currencyFormatter.format(stats.data ? ((stats.data.tax || 0)/100) : 0);
  const revenue = currencyFormatter.format(stats.data ? ((stats.data.amount || 0)/100) : 0);

  const foodEntries = stats.data && stats.data.foodList && Object.entries(stats.data.foodList).sort((a, b) => b[0].localeCompare(a[0]));
  const foodList = foodEntries ? foodEntries.map(entry => {
    return { sku: entry[0], ...entry[1] }
  }) : [];

  const drinkEntries = stats.data && stats.data.drinkList && Object.entries(stats.data.drinkList).sort((a, b) => b[0].localeCompare(a[0]));
  const drinkList = drinkEntries ? drinkEntries.map(entry => {
    return { sku: entry[0], ...entry[1] }
  }) : [];

  const otherEntries = stats.data && stats.data.otherList && Object.entries(stats.data.otherList).sort((a, b) => b[0].localeCompare(a[0]));
  const otherList = otherEntries ? otherEntries.map(entry => {
    return { sku: entry[0], ...entry[1] }
  }) : [];

  // https://material-ui.com/customization/palette/
  // color: primary, secondary, error, warning, info, success

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={refreshing} onClick={() => setRefreshing(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
          <DateFilter title={'MANAGE DELIVERIES'} rangeOptions={rangeOptions} defaultDateRange={dateRange} onDateRangeChange={onDateRangeChange}/>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <MetricPanel title="DELIVERIES"
                 color="warning"
                 icon={<RestaurantIcon className={classes.icon}/>}
                 value={orderCount}/>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
        <MetricPanel title="TIPS"
               color="info"
               icon={<AttachMoneyIcon className={classes.icon}/>}
               value={tipAmount}/>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <MetricPanel title="TAX"
                 color="primary"
                 icon={<AttachMoneyIcon className={classes.icon}/>}
                 value={tax}/>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <MetricPanel title="REVENUE"
                 color="success"
                 icon={<AttachMoneyIcon className={classes.icon}/>}
                 value={revenue}/>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <OrderSummary title="ENTRÉE SUMMARY" orders={foodList}/>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <OrderSummary title="DESSERT SUMMARY" orders={otherList}/>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <OrderSummary title="DRINK SUMMARY" orders={drinkList}/>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <OrderDetailsTable title="ORDER DETAILS" orders={orders}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default ManageOrders;
