import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from "react-router-dom";

const StaffRouteWithLayout = ({
  layout: Layout,
  component: Component,
  isAuthenticated,
  isVerifying,
  user,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isVerifying ? (
        <div />
      ) : isAuthenticated && user.staff ? (
        <Layout user={user}>
          <Component {...props} />
        </Layout>
      ) : (
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      )
    }
  />
);

StaffRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  user: PropTypes.object,
};

export default StaffRouteWithLayout;
