import { UPDATE_BILLING_SUMMARY,
         UPDATE_DELIVERY_SCHEDULE,
         UPDATE_DELIVERY_ADDRESS,
         UPDATE_BILLING_ADDRESS,
         UPDATE_PAYMENT_METHOD,
         UPDATE_PAYMENT_INTENT,
         UPDATE_PAYMENT_ORDER
       } from '../../actions/checkout/actionTypes';

import { DEFAULT_DELIVERY_ADDRESS,
         DEFAULT_BILLING_ADDRESS,
         getFullAddress
       } from '../../actions/checkout/actions';

import { nextDeliveryDate, getDefaultDeliveryTime } from 'redux/util';

const initialState = {
  orderDetails: {},
  deliverySchedule: { date: nextDeliveryDate().toDate(), time: getDefaultDeliveryTime() },
  deliveryAddress: { ...DEFAULT_DELIVERY_ADDRESS, fullAddress: getFullAddress(DEFAULT_DELIVERY_ADDRESS) },
  billingAddress: { ...DEFAULT_BILLING_ADDRESS, fullAddress: getFullAddress(DEFAULT_BILLING_ADDRESS) },
  paymentMethod: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_BILLING_SUMMARY:
      return {
        ...state,
        orderDetails: action.payload
      };
    case UPDATE_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: {
          ...action.payload,
          fullAddress: getFullAddress(action.payload)
        }
      };
    case UPDATE_DELIVERY_SCHEDULE:
      return {
        ...state,
        deliverySchedule: action.payload
      };
    case UPDATE_BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: {
          ...action.payload,
          fullAddress: getFullAddress(action.payload)
        }
      };
    case UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload
      };
    case UPDATE_PAYMENT_INTENT:
      return {
        ...state,
        paymentIntent: action.payload
      };
    case UPDATE_PAYMENT_ORDER:
      return {
        ...state,
        paymentOrder: action.payload
      };
    default:
      return state;
  }
}
