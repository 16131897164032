import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { updateFilters } from '../../../redux/actions/filters/actions';
import Checkbox from '../../Checkbox';
import {
  Button,
  ButtonGroup,
} from '@material-ui/core';


import './style.scss';

const filters = [
  {
    type: 'food',
    categories: ['appetizer', 'beef', 'pork', 'chicken', 'crab', 'egg', 'tofu', 'seafood', 'veggie', 'soup']
  },
  {
    type: 'dessert',
    categories: ['waffles', 'chocolate']
  },
  {
    type: 'drink',
    categories: ['tea']
    //categories: ['tea', 'cocktails', 'beer', 'wine', 'spirits']
  },
  {
    type: 'groceries',
    categories: ['beef', 'pork', 'chicken', 'crab', 'egg', 'tofu', 'seafood', 'veggie']
  },
];

class Filter extends Component {
  static propTypes = {
    updateFilters: PropTypes.func.isRequired,
    filters: PropTypes.array
  };

  state = {
    selectedFilter: filters[0]
  };

  componentDidMount() {
    this.selectedCheckboxes = new Set();
  }

  toggleCheckbox = label => {
    if (this.selectedCheckboxes.has(label)) {
      this.selectedCheckboxes.delete(label);
    } else {
      this.selectedCheckboxes.add(label);
    }

    const filter = {
      type: this.state.selectedFilter.type,
      categories: Array.from(this.selectedCheckboxes)
    }

    this.props.updateFilters(filter);
  };

  selectType = label => {
    if (this.state.selectedFilter.type !== label){
      this.selectedCheckboxes.clear();

      const selectedFilter = filters.find(f => f.type === label);

      const filter = {
        type: selectedFilter.type,
        categories: []
      }

      this.props.updateFilters(filter);

      this.setState({ selectedFilter });
    }
  };

  createButton = label => (
    <Button
      key={label}
      label={label}
      color={(this.state.selectedFilter.type === label) && "primary"}
      onClick={() => this.selectType(label)}>
      {label}
    </Button>
  );

  createButtonGroup = label => (
    <ButtonGroup variant="contained" aria-label="contained primary button group">
      { filters.map(f => this.createButton(f.type)) }
    </ButtonGroup>
  );

  createCheckbox = label => (
    <Checkbox
      classes="filters-available-size"
      label={label}
      handleCheckboxChange={this.toggleCheckbox}
      key={label}
    />
  );

  createCheckboxes = () => this.state.selectedFilter
                            && this.state.selectedFilter.categories.map(this.createCheckbox);

  render() {
    return (
      <div>
        <div className="filters">
          {/*<h4 className="title">Filters:</h4>*/}
          {this.createButtonGroup()}
        </div>
        <br/>
        <div className="filters">
          {this.createCheckboxes()}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { updateFilters }
)(Filter);
