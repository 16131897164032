import React from 'react';
import { connect } from "react-redux";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box, Divider, Typography, Button, colors } from '@material-ui/core';

import { enableDiscounts } from 'config';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.grey[50],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minHeight: 'fit-content',
    width: '100%',
    padding: theme.spacing(2),
  },
  media: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: 80,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto'
    }
  },
  content: {
    //padding: theme.spacing(1, 2)
  },
  spacing: {
    paddingBottom: theme.spacing(2)
  }
}));

const OrderSummary = ({ dispatch,
                        orderDetails,
                        className,
                        ...rest }) => {
  const classes = useStyles();

  const renderOrderSummary = () => {
    const items = [];

    const food = !!orderDetails.foodQuantity && (`${orderDetails.foodQuantity} ` + (orderDetails.foodQuantity === 1 ? `Entrée` : `Entrées`));
    food && items.push(food);

    const drinks = !!orderDetails.drinkQuantity && (`${orderDetails.drinkQuantity} ` + (orderDetails.drinkQuantity === 1 ? `Drink` : `Drinks`));
    drinks && items.push(drinks);

    const others = !!orderDetails.otherCount && (`${orderDetails.otherCount} ` + (orderDetails.otherCount === 1 ? `Item` : `Items`));
    others && items.push(others);

    return (
      <div style={{ width: '100%' }}>
        { !!food && <Box pl={1} pr={1}><Typography variant="body1">{food}</Typography></Box> }
        { !!drinks && <Box pl={1} pr={1}><Typography pl={1} pr={1} variant="body1">{drinks}</Typography></Box> }
        { !!others && <Box pl={1} pr={1}><Typography pl={1} pr={1} variant="body1">{others}</Typography></Box> }
        <Divider/>
        <Divider/>
        <Divider/>
        <Box display="flex" p={1} bgcolor="background.paper">
          <Box flexGrow={1}><Typography variant="overline" color="textSecondary">SUBTOTAL:</Typography></Box>
          <Box><Typography variant="overline" color="textSecondary">{orderDetails.subTotal}</Typography></Box>
        </Box>
        { /* totalDiscount */
          orderDetails.hasDiscount &&
          <Box display="flex" pl={1} pr={1} pt={1}>
            <Box flexGrow={1}><Typography variant="overline" color="textSecondary">DISCOUNT:</Typography></Box>
            <Box><Typography variant="overline" color="textSecondary">-{orderDetails.discount}</Typography></Box>
          </Box>
        }
        <Box display="flex" pl={1} pr={1}>
          <Box flexGrow={1}><Typography variant="overline" color="textSecondary">TAX:</Typography></Box>
          <Box><Typography variant="overline" color="textSecondary">{orderDetails.tax}</Typography></Box>
        </Box>
        <Box display="flex" pl={1} pr={1}>
          <Box flexGrow={1}><Typography variant="overline" color="textSecondary">DELIVERY FEE:</Typography></Box>
          <Box><Typography variant="overline" color="textSecondary">{!!orderDetails.deliveryFeeAmount ? orderDetails.deliveryFee : 'FREE'}</Typography></Box>
        </Box>
        <Box display="flex" pl={1} pr={1} pb={1}>
          <Box flexGrow={1}><Typography variant="overline" color="textSecondary">TIP:</Typography></Box>
          <Box><Typography variant="overline" color="textSecondary">{orderDetails.tip}</Typography></Box>
        </Box>
        <Divider/>
        <Divider/>
        <Divider/>
        <Box display="flex" p={1} bgcolor="background.paper">
          <Box flexGrow={1}><Typography variant="overline" color="textSecondary">TOTAL:</Typography></Box>
          <Box><Typography variant="overline" color="textSecondary">{orderDetails.totalPrice}</Typography></Box>
        </Box>
        <Divider/>
        <Divider/>
        <Divider/>
      </div>
    );
  }


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {/*
      <div className={classes.media}>
        {<img
          alt="EatSimplified Logo"
          style={{ borderRadius: 5 }}
          src="./images/logos/logo.png"
          height="36"
        />
      </div>*/}
      <div className={classes.content}>
        <Typography
          align="center"
          gutterBottom
          variant="h6"
        >
          ORDER SUMMARY
        </Typography>
        {/* enableDiscounts &&
            <Typography
              align="center"
              variant="body2"
              className={classes.spacing}
            >
              Prepay 5+ entrées for discount
            </Typography>
        */}
        {
          renderOrderSummary(orderDetails)
        }
      </div>
    </div>
  );
};

OrderSummary.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = () => ({
});

export default connect(
  mapStateToProps,
)(OrderSummary);
